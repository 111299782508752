import React from 'react';
import './LearnMore.css';

const LearnMore = () => {
  return (
    <section className="learn-more" id="learn-more">
      <div className="content">
        <h2>How Sibel Works</h2>
        <p>
          Sibel uses a state-of-the-art <strong>Retrieval-Augmented Generation (RAG)</strong> structure to generate clear, concise explanations for complex or hard-to-understand words, leveraging our extensive databases. Our API integrates seamlessly with client websites, offering an improved, more accessible user experience by making complex content more user-friendly.
        </p>

        <h3>What is RAG?</h3>
        <p>
          RAG (Retrieval-Augmented Generation) combines the strengths of retrieval-based methods with generation-based approaches to produce accurate, contextually relevant definitions and explanations. By retrieving relevant data from a large vectorized dataset, Sibel ensures that the generated content is precise and matches the user’s needs in real time.
        </p>

        <h3>Why RAG is Reliable</h3>
        <p>
          RAG models are renowned for their reliability, driven by their ability to quickly access and retrieve vast datasets. By fusing retrieval with generation, the model guarantees that the explanations are not only accurate but also contextually aligned with the content, reducing errors and improving overall quality. This dual mechanism allows us to offer highly accurate, real-time solutions tailored to user queries.
        </p>

        <h3>Benefits for Businesses</h3>
        <p>
          Integrating Sibel into your website enhances user engagement by making complex terminology accessible and understandable. This results in better customer experiences, reduces bounce rates, and ensures that users can find the information they need without getting overwhelmed. Our API works in real time, ensuring that your users always receive up-to-date and relevant explanations.
        </p>

        <h3>Security and Privacy</h3>
        <p>
          At Sibel, we prioritize data privacy and security. All data processed through our API is encrypted, ensuring that sensitive information remains protected at all times. Our system adheres to the highest standards of security, giving you peace of mind while offering seamless service to your users.
        </p>

        <h3>Customizable for Your Needs</h3>
        <p>
          Sibel's solution is flexible and adaptable to different industries or domains. Whether you're in finance, healthcare, or education, our API can be customize to cater to the specific terminology of your field, providing tailored explanations that match your content’s context and audience.
        </p>
      </div>
    </section>
  );
};

export default LearnMore;
