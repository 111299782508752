import React, { useState, useRef } from 'react';
import './Highlight.css';

const Highlight = ({ 
    id,
    word,
    setHighlightedId,
    setPopupPos,
    setIsPopupVisible,
    isTouchDevice,
}) => {
    const markRef = useRef(null);

    const [delayHandler, setDelayHandler] = useState(null);

    const showExplanation = (pointerPos) => {
        setHighlightedId(id);
        const mark = markRef.current;
        const parent = document.getElementById("offsetParent");

        const ws = mark.style.whiteSpace
        mark.style.whiteSpace = "nowrap";
        const oneLineHeight = mark.offsetHeight;
        mark.style.whiteSpace = ws;

        const boundingPos = mark.getBoundingClientRect();
        const relativePointerPos = { 
            x: pointerPos.x - boundingPos.x, 
            y: pointerPos.y - boundingPos.y
        };

        const currLineOffset = oneLineHeight 
            * Math.floor(relativePointerPos.y/oneLineHeight);

        const offsetBottom = parent.offsetHeight
            - mark.offsetTop
            - mark.offsetHeight;

        const leftmost = mark.offsetHeight === oneLineHeight
            ? mark.offsetLeft
            : boundingPos.x - parent.getBoundingClientRect().x;

        setTimeout(() => {
            const popup = document.getElementById(`explanation-${id}`);
            if (popup) {
                const top = offsetBottom < popup.offsetHeight 
                    ? mark.offsetTop-popup.offsetHeight+currLineOffset
                    : mark.offsetTop+oneLineHeight+currLineOffset;

                const margin = popup.offsetWidth * 0.2;
                const currXOffset = Math.max(
                    Math.min(
                        mark.offsetWidth-margin,
                        relativePointerPos.x
                    ),
                    margin
                );
                const left = Math.max(
                    0,
                    Math.min(
                        leftmost - popup.offsetWidth / 2 + currXOffset,
                        parent.offsetWidth - popup.offsetWidth
                    )
                );

                setPopupPos({ top, left });
            }
            setIsPopupVisible(true);
        }, 0);
    };

    const hideExplanation = () => {
        setIsPopupVisible(false);
        setHighlightedId(null);
    };

    const touchShowExplanation = (clickPos) => {
        showExplanation(clickPos);

        const touchHideExplanation = (event) => {
            const explanation = document.getElementById(`explanation-${id}`);

            if (explanation && !explanation.contains(event.target)) {
                hideExplanation();
                document.removeEventListener('click', touchHideExplanation);
            }
        };

        setTimeout(() => {
            document.addEventListener('click', touchHideExplanation);
        }, 0);
    }; 

    const handleOnMouseEnter = (event) => {
        const mousePos = { x: event.clientX, y:event.clientY };
        setDelayHandler(setTimeout(() => {
            showExplanation(mousePos);
        }, 300));
    }

    const handleOnMouseLeave = () => {
        if (delayHandler) {
            clearTimeout(delayHandler);
            setDelayHandler(null);
        }

        hideExplanation();
    }

    const handleOnClick = (event) => {
        const clickPos = { x: event.clientX, y:event.clientY };
        touchShowExplanation(clickPos);
    }

    return (
        <mark
            ref={ markRef }
            className="highlight" 
            onMouseEnter={ !isTouchDevice ? handleOnMouseEnter : undefined }
            onMouseLeave={ !isTouchDevice ? handleOnMouseLeave : undefined }
            onClick={ isTouchDevice ? handleOnClick : undefined }
        >
            { word }
        </mark>
    );
}

export default Highlight; 
