import React from 'react';
import './ContactUs.css';

const ContactUs = () => {
  return (
    <section className="contact-us">
      <h2>Contact Us</h2>
      <form>
        <div className="form-group">
          <div className="form-field">
            <label>Email</label>
            <input type="email" required />
          </div>
          <div className="form-field">
            <label>Company Name</label>
            <input type="text" required />
          </div>
        </div>
        <div className="form-field">
          <label>Message</label>
          <textarea required></textarea>
        </div>
        <button type="submit">Submit</button>
      </form>
    </section>
  );
};

export default ContactUs;
