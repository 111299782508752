import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer>
      <div className="footer-content">
        <div className="footer-text">
          <p>&copy; 2024 Sibel Technologies.</p>
          <p>All rights reserved.</p>
        </div>
        <div className="social-media">
          <a
            href="https://www.linkedin.com/company/sibel-technologies/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Sibel on LinkedIn"
          >
            <img src={`${process.env.PUBLIC_URL}/images/Linkdin30.png`} alt="LinkedIn" className="linkedin-icon" />
          </a>
          {/* Add more social media links here if needed */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
