import React from 'react';
import './WhySibel.css';

const WhySibel = () => {
  return (
    <section className="why-sibel">
      <h2>Why Choose Sibel?</h2>
      <div className="reasons">
        <div className="reason">
          <h3>Reliable</h3>
          <p>Sibel’s RAG model ensures that the explanations provided are precise and free from AI hallucinations, maintaining the integrity of the information.</p>
        </div>
        <div className="reason">
          <h3>Accurate</h3>
          <p>You have control over the data used for terminology simplification, allowing for flexible and adaptable solutions that meet unique business requirements.</p>
        </div>
        <div className="reason">
          <h3>Flexible</h3>
          <p>Sibel’s AI capabilities can be tailored to match your needs and infrastructure, from translation capabilities to tailored definitions, ensuring customer satisfaction.</p>
        </div>
      </div>
    </section>
  );
};

export default WhySibel;
