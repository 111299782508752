import React from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './Header.css';

const Header = ({ scrolled, toggle, isOpen }) => {
  return (
    <div className="sibelHeader">
      <header className={scrolled ? 'scrolled' : ''}>
        <div className="menu-container">
          <button
            className="menu-button"
            onClick={toggle}
            aria-label={isOpen ? 'Close Menu' : 'Open Menu'}
            aria-expanded={isOpen}
            aria-controls="sidebar"
          >
            {isOpen ? <FaTimes /> : <FaBars />}
          </button>
        </div>
        <div className="spacer" />
        <div className="logo-container">
          <Link to="/" className="logo-link">
            <div className="header-logo-container">
              <img
                src={`${process.env.PUBLIC_URL}/images/sibel-logo-l.png`}
                alt="Sibel Logo"
                className="logo"
              />
            </div>
          </Link>
        </div>
      </header>
    </div>
  );
};

export default Header;
