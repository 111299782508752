// src/pages/ForgotPassword.js

import React, { useState } from 'react';
import './ForgotPassword.css';
import { Link, useNavigate } from 'react-router-dom';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  // Simple email validation regex
  const validateEmail = (email) => {
    const re = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Reset messages
    setError('');
    setSuccess('');

    if (!email) {
      setError('Email is required.');
      return;
    }

    if (!validateEmail(email)) {
      setError('Please enter a valid email address.');
      return;
    }

    // TODO: Implement password reset logic here
    // For demonstration, we'll mock a successful response
    console.log('Password reset link sent to:', email);
    setSuccess('A password reset link has been sent to your email.');
    setEmail('');

    // Optionally, redirect to login page after a delay
    // setTimeout(() => navigate('/login'), 3000);
  };

  return (
    <div className="forgot-password-container">
      <form className="forgot-password-form" onSubmit={handleSubmit} noValidate>
        <h2 className="forgot-password-title">Forgot Password</h2>
        <p className="forgot-password-instruction">
          Enter your registered email address. We'll send you a link to reset your password.
        </p>

        {error && <span className="error-message">{error}</span>}
        {success && <span className="success-message">{success}</span>}

        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={error ? 'input-error' : ''}
            placeholder="Enter your email"
            required
          />
        </div>

        <button type="submit" className="submit-button">
          Reset Password
        </button>

        <div className="additional-links">
          <Link to="/login" className="link">
            Back to Login
          </Link>
        </div>
      </form>
    </div>
  );
};

export default ForgotPassword;
