const medicalData = {
    "originalText": "The Chevron doctrine arose from the Court's 1984 decision in Chevron U.S.A. Inc. v. Natural Resources Defense Council, Inc., 467 U.S. 837 (1984). That decision requires courts to give deference to a reasonable interpretation of an ambiguous statute by a government agency.\n\n\n\nBut it was not until 2011, in Mayo Foundation for Medical Education and Research, 562 U.S. 44 (2011), that the justices resolved a split in the lower courts and held that Chevron deference applied to interpretive tax regulations issued under Sec. 7805(a), which gives a general grant of authority to Treasury to \"prescribe all needful rules and regulations for enforcement\" of the Code. Before that decision, courts had generally given a lower level of deference to Treasury's interpretive regulations.\n\n\n \nIn January, the Court heard two cases — Loper Bright Enterprises, Inc. v. Raimondo, No. 22-451 (U.S. 1/17/24) (oral argument), and Relentless, Inc. v. Department of Commerce, No. 22-1219 (U.S. 1/17/24) (oral argument) — involving a National Marine Fisheries Service regulation that requires the herring industry to pay for observers on fishing boats. Both the D.C. Circuit and the First Circuit, respectively, applying the Chevron doctrine, upheld the rule.",
    "translations": {
        "gb": { 
            "translatedText": "The heart beats about 2.5 billion times over the average lifetime, pushing millions of gallons of blood to every part of the body. This steady flow carries with it oxygen, fuel, hormones, other compounds, and a host of essential cells. It also whisks away the waste products of metabolism. When the heart stops, essential functions fail, some almost instantly. \n Given the heart's never-ending workload, it's a wonder it performs so well, for so long, for so many people. But it can also fail, brought down by a poor diet and lack of exercise, smoking, infection, unlucky genes, and more. \n A key problem is atherosclerosis. This is the accumulation of pockets of cholesterol-rich gunk inside the arteries. These pockets, called plaque, can limit blood flow through arteries that nourish the heart \u2014 the coronary arteries \u2014 and other arteries throughout the body. When a plaque breaks apart, it can cause a heart attack or stroke. \n Although many people develop some form of cardiovascular disease as they get older, it isn't inevitable. A healthy lifestyle, especially when started at a young age, goes a long way to preventing cardiovascular disease. Lifestyle changes and medications can nip heart-harming trends, like high blood pressure or high cholesterol, in the bud before they cause damage. And a variety of medications, operations, and devices can help support the heart if damage occurs.",
            "highlights": [
                {
                    "startIdx": 143,
                    "endIdx": 147,
                    "keyword": "flow",
                    "definition": "The term \"flow\" in the context of blood circulation refers to the continuous movement of blood through the vascular system, which is essential for delivering oxygen, nutrients, and hormones to tissues throughout the body. This flow is regulated by various physiological mechanisms that maintain homeostasis, ensuring that blood pressure, temperature, and other critical factors are kept within optimal ranges for cellular function. In essence, effective blood flow is vital for sustaining life, as it supports metabolic processes and the overall health of tissues and organs.",
                    "simplified": "In the context of blood circulation, \"flow\" refers to the ongoing movement of blood through the vessels, which is crucial for supplying oxygen and nutrients to body tissues while maintaining stable conditions necessary for cellular health.",
                    "example": "Imagine you're running a marathon. As you exert yourself, your heart pumps blood faster, ensuring a steady flow of oxygen and nutrients to your muscles, which helps them perform efficiently and fend off fatigue. If this blood flow were to slow down or stop, you'd quickly feel exhausted, highlighting just how crucial that continuous flow is for your overall stamina and health."
                },
                {
                    "startIdx": 178,
                    "endIdx": 186,
                    "keyword": "hormones",
                    "definition": "Hormones are biochemical messengers produced by glands in the endocrine system that regulate various physiological processes in the body, including metabolism, growth, and mood. They are released into the bloodstream and transported to target organs or tissues, where they exert their effects by binding to specific receptors. In the context provided, hormones play a critical role in maintaining homeostasis by influencing bodily functions alongside other essential compounds in the circulatory system.",
                    "simplified": "Hormones are chemical signals produced by glands that travel through the bloodstream to control various body functions, such as metabolism, growth, and mood, helping to keep internal conditions stable.",
                    "example": "Imagine you're feeling really stressed about an upcoming exam. Your brain releases cortisol, a hormone that helps manage stress by increasing your energy and alertness. This surge of cortisol travels through your bloodstream, preparing your body to tackle the challenge ahead by enhancing your focus and stamina."
                },

                {
                    "startIdx": 278,
                    "endIdx": 288,
                    "keyword": "metabolism",
                    "definition": "Metabolism refers to the complex biochemical processes that occur within cells to convert nutrients into energy, facilitating vital functions such as growth, repair, and maintenance of cellular structures. This process encompasses two main pathways: catabolism, which breaks down molecules to release energy, and anabolism, which uses energy to construct cellular components. In the context provided, effective metabolism is crucial for maintaining cellular function and removing waste products, which is compromised when the heart fails to adequately supply blood throughout the body.",
                    "simplified": "Metabolism is the collection of biochemical reactions in cells that transform nutrients into energy for essential functions like growth and repair, while also managing waste removal, which can be disrupted if blood flow from the heart is insufficient.",
                    "example": "Imagine you're running a marathon. Your metabolism is like your body's energy factory, breaking down the food you've eaten into fuel that powers your muscles, while also clearing away any waste produced during the race. If your heart can't pump enough blood to supply oxygen and nutrients, your metabolism slows down, and you might hit a wall, making it hard to keep going."
                },
                {
                    "startIdx": 534,
                    "endIdx": 542,
                    "keyword": "exercise",
                    "definition": "The term \"exercise\" refers to physical activity that is planned, structured, and repetitive, aimed at improving or maintaining physical fitness and overall health. In the context provided, a lack of exercise is identified as a contributing risk factor for health issues, alongside poor diet and other lifestyle choices, which can lead to diseases such as heart disease. Regular exercise is vital for enhancing cardiovascular health, managing weight, and reducing the risk of chronic illnesses, thereby underscoring its importance in a balanced lifestyle.",
                    "simplified": "\"Exercise\" denotes organized physical activity performed regularly to boost or sustain fitness and health, and its absence is highlighted as a significant risk factor for various diseases, emphasizing its critical role in a healthy lifestyle.",
                    "example": "Imagine Sarah, who spends most of her day sitting at a desk and often chooses fast food for meals. As a result, she notices she\u2019s gaining weight and feeling more fatigued, increasing her risk for heart disease. By incorporating regular exercise, like walking or joining a fitness class, Sarah can improve her overall health and lower her chances of developing serious conditions, highlighting the critical role of physical activity in maintaining wellness."
                },
                {
                    "startIdx": 553,
                    "endIdx": 562,
                    "keyword": "infection",
                    "definition": "Infection refers to the invasion and multiplication of pathogenic microorganisms, such as bacteria, viruses, fungi, or parasites, within the body, leading to a disruption of normal physiological functions. In the context provided, infection is identified as one of several risk factors\u2014alongside poor diet, lack of exercise, and genetic predisposition\u2014that can contribute to the development of various diseases, including heart disease. Understanding infections as risk factors highlights the interplay of lifestyle choices and biological vulnerabilities in the overall health outcomes of individuals.",
                    "simplified": "Infection involves the entry and growth of harmful microorganisms in the body, which can disturb normal bodily functions and is recognized as a contributing factor to various diseases, similar to poor diet or lack of exercise.",
                    "example": "Imagine a busy professional who often skips meals and relies on fast food, neglects exercise, and smokes to cope with stress. One day, they catch the flu (an infection), which not only makes them feel awful but also puts extra strain on their already vulnerable heart. This scenario illustrates how infections, compounded by unhealthy lifestyle choices, can significantly impact overall health and accelerate the risk of serious diseases."
                },
                {
                    "startIdx": 608,
                    "endIdx": 623,
                    "keyword": "atherosclerosis",
                    "definition": "Atherosclerosis is a pathological condition characterized by the buildup of lipids, cholesterol, and other cellular debris within the arterial walls, leading to the formation of plaques that can narrow and harden the arteries. This process is primarily influenced by elevated levels of low-density lipoprotein (LDL) cholesterol, often referred to as \"bad cholesterol,\" which contributes to the increased risk of cardiovascular events such as heart attacks and strokes. The condition is exacerbated by various factors, including genetic predisposition, lifestyle choices, and underlying health issues, ultimately compromising blood flow and cardiovascular health.",
                    "simplified": "Atherosclerosis is a condition where fatty substances, including \"bad\" cholesterol, accumulate in the arteries, causing them to narrow and harden, which increases the risk of heart attacks and strokes.",
                    "example": "Imagine a busy road that gradually gets blocked with debris, causing traffic to slow down. Atherosclerosis acts like that blockage in your arteries, where excess \"bad cholesterol\" builds up and narrows the path for blood to flow. This narrowing increases the risk of serious issues like heart attacks, especially if you have a family history or unhealthy habits, just like how poor road maintenance can lead to accidents on a crowded highway."
                },
                {
                    "startIdx": 664,
                    "endIdx": 675,
                    "keyword": "cholesterol",
                    "definition": "Cholesterol is a waxy, fat-like substance found in every cell of the body, essential for the production of hormones, vitamin D, and bile acids that help digest fat. In the context of sclerosis, particularly atherosclerosis, cholesterol can accumulate in the arterial walls, forming plaques that narrow and harden the arteries, potentially leading to cardiovascular diseases. This pathological process contributes to the broader category of arteriosclerosis, which encompasses various forms of arterial thickening and loss of elasticity, significantly impacting blood flow.",
                    "simplified": "Cholesterol is a fatty substance vital for hormone production and digestion, but when it builds up in artery walls, it forms plaques that can narrow and harden the arteries, increasing the risk of heart-related issues.",
                    "example": "Imagine someone who enjoys a diet high in saturated fats, like fried foods and fatty cuts of meat. Over time, the excess cholesterol from these foods can lead to a buildup of plaque in their arteries, making them less flexible and narrowing the passage for blood flow. As a result, this person might experience chest pain during physical activities, signaling the potential onset of serious heart issues due to arteriosclerosis."
                },
                {
                    "startIdx": 729,
                    "endIdx": 735,
                    "keyword": "plaque",
                    "definition": "Plaque refers to an abnormal accumulation of lipids, cellular debris, and inflammatory cells within the arterial walls, which can significantly impede blood flow. This accumulation, often characterized by the presence of foam cells\u2014lipid-laden macrophages\u2014develops over time and contributes to atherosclerosis, a condition associated with cardiovascular disease. The formation of plaque can lead to serious health complications, including heart attacks and strokes, due to the narrowing of arteries and potential for plaque rupture.",
                    "simplified": "Plaque is an unhealthy buildup of fats and other substances in the artery walls that reduces blood flow, potentially leading to serious cardiovascular issues.",
                    "example": "Imagine a busy highway where a lot of cars are trying to get through, but one lane is blocked by a pile of debris; this is similar to how plaque works in your arteries. Over time, unhealthy eating habits can lead to the buildup of fat and other substances inside the arteries, narrowing them and making it harder for blood to flow\u2014just like that blocked lane slows down traffic. If the plaque breaks apart, it can cause a sudden blockage that might result in a heart attack, highlighting why it's crucial to maintain a healthy diet and lifestyle."
                },
                {
                    "startIdx": 753,
                    "endIdx": 757,
                    "keyword": "flow",
                    "definition": "In the context of coronary artery disease, \"flow\" refers to the movement of blood through the arteries that supply oxygen and nutrients to the heart muscle. Impaired blood flow occurs due to the narrowing of these arteries caused by the accumulation of plaque, which can lead to serious cardiovascular events such as chest pain or heart attacks. This term is crucial in understanding the physiological impact of arterial blockages on heart function and overall cardiovascular health.",
                    "simplified": "In the context of coronary artery disease, \"flow\" denotes the circulation of blood through arteries that deliver essential oxygen and nutrients to the heart, which can be obstructed by plaque buildup, potentially resulting in significant health issues like chest pain or heart attacks.",
                    "example": "Imagine a garden hose that has a kink in it; when you turn on the water, the flow is restricted, making it hard for the plants to get the water they need. Similarly, in coronary artery disease, plaque buildup narrows the arteries supplying blood to the heart, which can lead to chest pain or even a heart attack if the flow is significantly impaired. Just like fixing the kink in the hose helps restore water flow, addressing the plaque in arteries can improve blood flow and heart health."
                },
                {
                    "startIdx": 804,
                    "endIdx": 812,
                    "keyword": "coronary",
                    "definition": "The term \"coronary\" refers to the network of arteries that supply blood to the myocardium, or heart muscle, specifically the coronary arteries that branch off from the aorta. These arteries are crucial for delivering oxygen-rich blood to the heart, ensuring its proper function and health. In the context of cardiovascular health, any obstruction or damage to these arteries can lead to significant conditions, such as coronary artery disease, which can impair cardiac performance.",
                    "simplified": "The term \"coronary\" describes the system of arteries responsible for delivering oxygenated blood to the heart muscle, highlighting their importance in maintaining heart health and function.",
                    "example": "Imagine you're running a marathon, and your heart needs extra oxygen to keep up with your body's demands. The coronary arteries are like the fuel lines delivering that oxygen-rich blood to your heart muscle; if there's a blockage in these arteries, it\u2019s like having a kink in the hose, which can lead to chest pain or even a heart attack. So, keeping those coronary arteries healthy is essential for your heart to perform its best during that race!"
                },
                {
                    "startIdx": 907,
                    "endIdx": 919,
                    "keyword": "heart attack",
                    "definition": "A heart attack, clinically referred to as a myocardial infarction, occurs when blood flow to a portion of the heart muscle is obstructed, often due to the rupture of plaque within the coronary arteries. This obstruction can lead to damage or death of the heart tissue, as the affected area is deprived of oxygen and nutrients. Risk factors include atherosclerosis, characterized by the accumulation of lipid-laden foam cells that form plaques, ultimately narrowing the arteries and increasing the likelihood of cardiovascular events.",
                    "simplified": "A heart attack, or myocardial infarction, happens when blood flow to a section of the heart is blocked, often by ruptured plaque in the coronary arteries, which can harm or kill heart tissue due to a lack of oxygen and nutrients, with atherosclerosis being a major contributing factor.",
                    "example": "Imagine a 55-year-old man named Tom who has been feeling unusually fatigued and has a tight sensation in his chest while jogging. After a visit to the doctor, he learns that he has a buildup of plaque in his arteries due to atherosclerosis, which puts him at risk for a heart attack. One day, as he pushes himself during his morning run, a plaque ruptures, blocking blood flow to his heart and leading to a heart attack, highlighting the importance of monitoring heart health and lifestyle choices."
                },
                {
                    "startIdx": 923,
                    "endIdx": 929,
                    "keyword": "stroke",
                    "definition": "A stroke is a medical condition that occurs when there is an interruption of blood supply to the brain, leading to the death of brain cells due to lack of oxygen and nutrients. This can result from either a blockage of blood vessels (ischemic stroke) or the rupture of a blood vessel (hemorrhagic stroke). The severity and consequences of a stroke can vary widely, often resulting in lasting neurological deficits depending on the area of the brain affected and the duration of the blood supply disruption.",
                    "simplified": "A stroke is a serious medical event that happens when blood flow to the brain is blocked or reduced, leading to brain cell damage due to insufficient oxygen and nutrients, which can result from either a clot or a burst blood vessel.",
                    "example": "Imagine a busy office worker who often skips meals and neglects their health. One day, they suddenly lose the ability to speak and feel weakness on one side of their body; this could be a stroke caused by a blockage in the blood vessels to their brain, highlighting the importance of maintaining a healthy lifestyle to prevent such serious medical conditions. Recognizing the symptoms promptly can be crucial, as quick treatment can minimize brain damage and improve recovery outcomes."
                },
                {
                    "startIdx": 975,
                    "endIdx": 989,
                    "keyword": "cardiovascular",
                    "definition": "The term \"cardiovascular\" pertains to the system of the heart (cardio) and blood vessels (vascular), encompassing the organs and tissues involved in the circulation of blood throughout the body. Cardiovascular diseases, such as coronary artery disease, arise from conditions that impair blood flow, often leading to ischemia, angina pectoris, or other serious complications. While the risk of developing cardiovascular disease increases with age, it is influenced by various modifiable factors, highlighting the importance of lifestyle choices in prevention.",
                    "simplified": "The term \"cardiovascular\" refers to the heart and blood vessels, focusing on the network responsible for circulating blood in the body, and includes diseases that disrupt this circulation, often linked to lifestyle factors that can be changed to reduce risk.",
                    "example": "Imagine a middle-aged man named Tom who enjoys jogging but has recently started experiencing chest pain after running a certain distance. This pain is a sign of angina pectoris, which indicates that his heart is struggling to get enough blood due to narrowed arteries\u2014a common issue in cardiovascular disease. By making healthier choices like improving his diet and quitting smoking, Tom can significantly reduce his risk of worsening his cardiovascular health and enjoy a more active lifestyle."
                },
                {
                    "startIdx": 1222,
                    "endIdx": 1241,
                    "keyword": "high blood pressure",
                    "definition": "High blood pressure, or hypertension, is a chronic medical condition characterized by consistently elevated arterial pressure, which can lead to significant cardiovascular complications if left unmanaged. It is often influenced by factors such as diet, lifestyle, and genetics, and is typically treated with various medications aimed at reducing the strain on the heart and arteries. Managing high blood pressure is critical to preventing more severe health issues, such as heart attacks and strokes, by maintaining appropriate blood flow and minimizing cardiovascular risk.",
                    "simplified": "High blood pressure, also known as hypertension, is a long-term condition where the force of blood against artery walls is consistently too high, potentially leading to serious heart-related health problems if not properly managed through lifestyle changes and medications.",
                    "example": "Imagine a busy professional, Alex, who often skips meals and relies on caffeine to get through the day. After a routine check-up, Alex discovers that their blood pressure is higher than normal, putting them at risk for serious heart issues. By making dietary changes, like eating more fruits and vegetables, and taking prescribed medication, Alex can manage their high blood pressure, reducing the risk of future heart attacks or strokes."
                },
                {
                    "startIdx": 1250,
                    "endIdx": 1261,
                    "keyword": "cholesterol",
                    "definition": "Cholesterol is a waxy, fat-like substance found in every cell of the body, essential for producing hormones, vitamin D, and substances that aid in digestion. It is carried through the bloodstream by lipoproteins, with low-density lipoprotein (LDL) often referred to as \"bad cholesterol\" due to its propensity to accumulate in arterial walls, thereby increasing the risk of cardiovascular diseases such as heart attacks and strokes. Monitoring and managing cholesterol levels is crucial, particularly in the context of other risk factors like fluctuating blood pressure, to prevent long-term health complications.",
                    "simplified": "Cholesterol is a fatty substance present in all body cells that is vital for hormone production and digestion, but high levels, particularly of low-density lipoprotein (LDL), can lead to artery blockage and increase the risk of heart disease and strokes, making it important to monitor alongside other health indicators like blood pressure.",
                    "example": "Imagine you're at a routine check-up, and your doctor tells you that your cholesterol levels are higher than normal. They explain that having too much \"bad\" LDL cholesterol can clog your arteries, similar to how grease can build up in a pipe, which can lead to serious issues like heart attacks. By making some dietary changes and exercising more, you can help lower your cholesterol levels and keep your heart healthy!"
                }
            ]
        
        },
        "se": {
            "translatedText": "Hjärtat slår ungefär 2,5 miljarder gånger under en genomsnittlig livstid och pumpar miljontals liter blod till varje del av kroppen. Detta stadiga flöde för med sig syre, näring, hormoner, andra föreningar och en mängd viktiga celler. Det transporterar också bort de avfallsprodukter som uppstår vid ämnesomsättningen. När hjärtat slutar slå upphör viktiga funktioner, vissa nästan omedelbart.\n\nMed tanke på hjärtats aldrig sinande arbetsbelastning är det anmärkningsvärt att det fungerar så bra, så länge, för så många människor. Men det kan också sluta fungera, till följd av en dålig kost och brist på motion, rökning, infektioner, otur med generna och mer.\n\nEtt centralt problem är åderförkalkning. Detta är ansamlingen av kolesterolrika avlagringar inuti artärerna. Dessa avlagringar, kallade plack, kan begränsa blodflödet genom artärerna som ger näring åt hjärtat – kransartärerna – och andra artärer i hela kroppen. När en plack brister kan det orsaka en hjärtinfarkt eller stroke.\n\nÄven om många människor utvecklar någon form av hjärt-kärlsjukdom när de blir äldre, är det inte oundvikligt. En hälsosam livsstil, särskilt om den påbörjas i unga år, gör mycket för att förebygga hjärt-kärlsjukdomar. Livsstilsförändringar och mediciner kan stoppa skadliga trender, som högt blodtryck eller högt kolesterol, innan de orsakar skada. Och en mängd olika mediciner, operationer och apparater kan hjälpa till att stödja hjärtat om skador uppstår.",
            "highlights": [
                {
                    "startIdx": 147,
                    "endIdx": 152,
                    "keyword": "flöde",
                    "definition": "Begreppet \"flöde\" i sammanhanget av blodcirkulation avser den kontinuerliga rörelsen av blod genom kärlsystemet, vilket är avgörande för att leverera syre, näringsämnen och hormoner till vävnader i hela kroppen. Detta flöde regleras av olika fysiologiska mekanismer som upprätthåller homeostas och säkerställer att blodtryck, temperatur och andra kritiska faktorer hålls inom optimala intervall för cellfunktion. Effektivt blodflöde är i grunden livsviktigt, eftersom det stöder metaboliska processer och den övergripande hälsan hos vävnader och organ.",
                    "simplified": "I sammanhanget av blodcirkulation avser \"flöde\" den pågående rörelsen av blod genom kärlen, vilket är avgörande för att förse kroppens vävnader med syre och näring samtidigt som det upprätthåller stabila förhållanden som är nödvändiga för cellernas hälsa.",
                    "example": "Föreställ dig att du springer ett maraton. När du anstränger dig pumpar ditt hjärta blod snabbare, vilket säkerställer ett stadigt flöde av syre och näringsämnen till dina muskler, vilket hjälper dem att prestera effektivt och motstå trötthet. Om detta blodflöde skulle sakta ner eller stanna, skulle du snabbt känna dig utmattad, vilket understryker hur viktigt detta kontinuerliga flöde är för din uthållighet och hälsa."
                },

                {
                    "startIdx": 179,
                    "endIdx": 187,
                    "keyword": "hormoner",
                    "definition": "Hormoner är biokemiska budbärare som produceras av körtlar i det endokrina systemet och som reglerar olika fysiologiska processer i kroppen, inklusive ämnesomsättning, tillväxt och humör. De frigörs i blodomloppet och transporteras till målorgan eller vävnader, där de utövar sina effekter genom att binda till specifika receptorer. I det givna sammanhanget spelar hormoner en avgörande roll i att upprätthålla homeostas genom att påverka kroppsfunktioner tillsammans med andra viktiga föreningar i cirkulationssystemet.",
                    "simplified": "Hormoner är kemiska signaler som produceras av körtlar och som reser genom blodomloppet för att kontrollera olika kroppsfunktioner, såsom ämnesomsättning, tillväxt och humör, och bidrar till att hålla kroppens inre förhållanden stabila.",
                    "example": "Föreställ dig att du känner dig väldigt stressad inför ett kommande prov. Din hjärna frigör kortisol, ett hormon som hjälper till att hantera stress genom att öka din energi och vaksamhet. Denna kortisolökning färdas genom blodomloppet och förbereder din kropp för att hantera utmaningen genom att förbättra ditt fokus och din uthållighet."
                },
                {
                    "startIdx": 300,
                    "endIdx": 317,
                    "keyword": "ämnesomsättning",
                    "definition": "Ämnesomsättning avser de komplexa biokemiska processer som sker i cellerna för att omvandla näringsämnen till energi och underlätta viktiga funktioner som tillväxt, reparation och underhåll av cellstrukturer. Denna process omfattar två huvudvägar: katabolism, som bryter ner molekyler för att frigöra energi, och anabolism, som använder energi för att bygga upp cellulära komponenter. I det givna sammanhanget är effektiv ämnesomsättning avgörande för att upprätthålla cellfunktioner och avlägsna avfallsprodukter, vilket komprometteras när hjärtat inte kan tillräckligt förse kroppen med blod.",
                    "simplified": "Ämnesomsättning är samlingen av biokemiska reaktioner i celler som omvandlar näringsämnen till energi för viktiga funktioner som tillväxt och reparation, samtidigt som avfall hanteras, vilket kan störas om blodflödet från hjärtat är otillräckligt.",
                    "example": "Föreställ dig att du springer ett maraton. Din ämnesomsättning är som din kropps energifabrik, som bryter ner maten du ätit till bränsle som driver dina muskler, samtidigt som den rensar bort avfall som produceras under loppet. Om ditt hjärta inte kan pumpa tillräckligt med blod för att förse syre och näringsämnen, saktar ämnesomsättningen ner, och du kan drabbas av utmattning, vilket gör det svårt att fortsätta."
                },
                {
                    "startIdx": 605,
                    "endIdx": 611,
                    "keyword": "motion",
                    "definition": "Begreppet \"motion\" avser fysisk aktivitet som är planerad, strukturerad och repetitiv, med syfte att förbättra eller upprätthålla fysisk kondition och allmän hälsa. I det givna sammanhanget identifieras brist på motion som en bidragande riskfaktor för hälsoproblem, tillsammans med dålig kost och andra livsstilsval, vilket kan leda till sjukdomar som hjärtsjukdomar. Regelbunden motion är avgörande för att förbättra hjärt-kärlhälsan, hantera vikt och minska risken för kroniska sjukdomar, vilket understryker dess betydelse i en balanserad livsstil.",
                    "simplified": "\"Motion\" betecknar organiserad fysisk aktivitet som utförs regelbundet för att förbättra eller upprätthålla kondition och hälsa, och dess frånvaro lyfts fram som en betydande riskfaktor för olika sjukdomar, vilket understryker dess avgörande roll i en hälsosam livsstil.",
                    "example": "Föreställ dig Sarah, som spenderar större delen av dagen sittande vid ett skrivbord och ofta väljer snabbmat till måltider. Som ett resultat märker hon att hon går upp i vikt och känner sig mer trött, vilket ökar risken för hjärtsjukdomar. Genom att införliva regelbunden motion, som att gå promenader eller delta i en träningsklass, kan Sarah förbättra sin allmänna hälsa och minska risken för att utveckla allvarliga tillstånd, vilket belyser den viktiga rollen som fysisk aktivitet spelar för att upprätthålla välbefinnande."
                },
                {
                    "startIdx": 622,
                    "endIdx": 633,
                    "keyword": "infektion",
                    "definition": "Infektion avser invasion och förökning av patogena mikroorganismer, såsom bakterier, virus, svampar eller parasiter, i kroppen, vilket leder till störningar av normala fysiologiska funktioner. I det givna sammanhanget identifieras infektion som en av flera riskfaktorer – tillsammans med dålig kost, brist på motion och genetisk benägenhet – som kan bidra till utvecklingen av olika sjukdomar, inklusive hjärtsjukdomar. Att förstå infektioner som riskfaktorer belyser samspelet mellan livsstilsval och biologiska sårbarheter i individers hälsoutfall.",
                    "simplified": "Infektion innebär att skadliga mikroorganismer tränger in och växer i kroppen, vilket kan störa normala kroppsfunktioner och är en erkänd bidragande faktor till olika sjukdomar, liksom dålig kost eller brist på motion.",
                    "example": "Föreställ dig en upptagen yrkesperson som ofta hoppar över måltider, förlitar sig på snabbmat, försummar motion och röker för att hantera stress. En dag får de influensan (en infektion), vilket inte bara får dem att må dåligt utan också sätter extra press på deras redan sårbara hjärta. Detta scenario illustrerar hur infektioner, i kombination med ohälsosamma livsstilsval, kan påverka den allmänna hälsan avsevärt och påskynda risken för allvarliga sjukdomar."
                },
                {
                    "startIdx": 686,
                    "endIdx": 700,
                    "keyword": "åderförkalkning",
                    "definition": "Åderförkalkning är ett patologiskt tillstånd som kännetecknas av ansamling av lipider, kolesterol och andra cellrester i artärväggarna, vilket leder till bildning av plack som kan förtränga och härda artärerna. Denna process påverkas främst av förhöjda nivåer av lågdensitetslipoprotein (LDL) kolesterol, ofta kallat \"dåligt kolesterol\", vilket bidrar till en ökad risk för hjärt-kärlhändelser som hjärtinfarkt och stroke. Tillståndet förvärras av olika faktorer, inklusive genetisk benägenhet, livsstilsval och underliggande hälsoproblem, vilket i slutändan försämrar blodflödet och hjärt-kärlhälsan.",
                    "simplified": "Åderförkalkning är ett tillstånd där fetthaltiga substanser, inklusive \"dåligt\" kolesterol, ackumuleras i artärerna, vilket gör dem trängre och hårdare och ökar risken för hjärtinfarkt och stroke.",
                    "example": "Föreställ dig en trafikerad väg som gradvis blockeras av skräp, vilket gör att trafiken saktar ner. Åderförkalkning fungerar som den blockeringen i dina artärer, där överskott av \"dåligt kolesterol\" byggs upp och gör vägen smalare för blodet att flöda. Denna förträngning ökar risken för allvarliga problem som hjärtinfarkt, särskilt om du har en familjehistoria eller ohälsosamma vanor, precis som dåligt vägunderhåll kan leda till olyckor på en överbelastad motorväg."
                },
                {
                    "startIdx": 727,
                    "endIdx": 741,
                    "keyword": "kolesterol",
                    "definition": "Kolesterol är ett vaxliknande, fettliknande ämne som finns i varje cell i kroppen och är nödvändigt för produktion av hormoner, vitamin D och gallsyror som hjälper till att smälta fett. I sammanhanget av skleros, särskilt åderförkalkning, kan kolesterol ackumuleras i artärväggarna och bilda plack som förtränger och härdar artärerna, vilket potentiellt kan leda till hjärt-kärlsjukdomar. Denna patologiska process bidrar till den bredare kategorin arterioskleros, som omfattar olika former av förtjockning och förlust av elasticitet i artärerna, vilket avsevärt påverkar blodflödet.",
                    "simplified": "Kolesterol är ett fettliknande ämne som är avgörande för hormonproduktion och matsmältning, men när det byggs upp i artärväggarna bildar det plack som kan förtränga och härda artärerna, vilket ökar risken för hjärtproblem.",
                    "example": "Föreställ dig någon som njuter av en diet rik på mättat fett, som stekt mat och feta köttstycken. Med tiden kan överskottet av kolesterol från dessa livsmedel leda till uppbyggnad av plack i deras artärer, vilket gör dem mindre flexibla och förtränger passagen för blodflöde. Som ett resultat kan denna person uppleva bröstsmärta under fysiska aktiviteter, vilket signalerar början av allvarliga hjärtproblem på grund av arterioskleros."
                },
                {
                    "startIdx": 798,
                    "endIdx": 803,
                    "keyword": "plack",
                    "definition": "Plack avser en onormal ansamling av lipider, cellrester och inflammatoriska celler i artärväggarna, vilket kan avsevärt hindra blodflödet. Denna ansamling, som ofta kännetecknas av närvaron av skumceller – lipidfyllda makrofager – utvecklas över tid och bidrar till åderförkalkning, ett tillstånd som är associerat med hjärt-kärlsjukdomar. Bildandet av plack kan leda till allvarliga hälsokomplikationer, inklusive hjärtinfarkt och stroke, på grund av artärernas förträngning och risken för plackruptur.",
                    "simplified": "Plack är en ohälsosam uppbyggnad av fetter och andra ämnen i artärväggarna som minskar blodflödet och potentiellt leder till allvarliga hjärt-kärlproblem.",
                    "example": "Föreställ dig en trafikerad motorväg där många bilar försöker ta sig fram, men en fil är blockerad av en hög med skräp; detta liknar hur plack fungerar i dina artärer. Med tiden kan ohälsosamma matvanor leda till uppbyggnad av fett och andra ämnen inuti artärerna, vilket gör dem trängre och gör det svårare för blodet att flöda – precis som den blockerade filen saktar ner trafiken. Om placken spricker kan den orsaka en plötslig blockering som kan resultera i en hjärtattack, vilket belyser varför det är viktigt att upprätthålla en hälsosam kost och livsstil."
                },

                {
                    "startIdx": 818,
                    "endIdx": 828,
                    "keyword": "flöde",
                    "definition": "I sammanhanget av kranskärlssjukdom avser \"flöde\" blodets rörelse genom artärerna som förser hjärtmuskeln med syre och näringsämnen. Försämrat blodflöde uppstår på grund av förträngningen av dessa artärer orsakad av ansamling av plack, vilket kan leda till allvarliga hjärt-kärlhändelser som bröstsmärta eller hjärtinfarkt. Denna term är avgörande för att förstå den fysiologiska påverkan av artärblockeringar på hjärtats funktion och den allmänna hjärt-kärlhälsan.",
                    "simplified": "I sammanhanget av kranskärlssjukdom betecknar \"flöde\" cirkulationen av blod genom artärerna som levererar syre och näringsämnen till hjärtat, vilket kan hindras av plackuppbyggnad och potentiellt leda till allvarliga hälsoproblem som bröstsmärta eller hjärtinfarkt.",
                    "example": "Föreställ dig en trädgårdsslang som har en knut i sig; när du slår på vattnet är flödet begränsat, vilket gör det svårt för växterna att få det vatten de behöver. På liknande sätt, vid kranskärlssjukdom, förträngs artärerna som förser hjärtat med blod av plackuppbyggnad, vilket kan leda till bröstsmärta eller till och med en hjärtinfarkt om flödet är allvarligt förhindrat. Precis som att åtgärda knuten i slangen hjälper till att återställa vattenflödet, kan behandling av placken i artärerna förbättra blodflödet och hjärthälsan."
                },
                {
                    "startIdx": 873,
                    "endIdx": 887,
                    "keyword": "kranskärl",
                    "definition": "Begreppet \"kranskärl\" avser nätverket av artärer som förser myokardiet, eller hjärtmuskeln, med blod, specifikt kranskärlen som förgrenar sig från aortan. Dessa artärer är avgörande för att leverera syrerikt blod till hjärtat och säkerställa dess korrekta funktion och hälsa. I sammanhanget av hjärt-kärlhälsa kan varje blockering eller skada på dessa artärer leda till betydande tillstånd, såsom kranskärlssjukdom, som kan försämra hjärtats prestanda.",
                    "simplified": "Begreppet \"kranskärl\" beskriver systemet av artärer som ansvarar för att leverera syrerikt blod till hjärtmuskeln och betonar deras betydelse för att upprätthålla hjärtats hälsa och funktion.",
                    "example": "Föreställ dig att du springer ett maraton, och ditt hjärta behöver extra syre för att hålla jämna steg med kroppens krav. Kranskärlen är som bränsleledningarna som levererar det syrerika blodet till hjärtmuskeln; om det finns en blockering i dessa artärer är det som att ha en knut i slangen, vilket kan leda till bröstsmärta eller till och med en hjärtinfarkt. Så att hålla kranskärlen friska är avgörande för att ditt hjärta ska kunna prestera sitt bästa under loppet!"
                },
                {
                    "startIdx": 963,
                    "endIdx": 976,
                    "keyword": "hjärtinfarkt",
                    "definition": "En hjärtinfarkt, kliniskt kallad hjärtinfarkt, inträffar när blodflödet till en del av hjärtmuskeln blockeras, ofta på grund av att plack brister i kranskärlen. Denna blockering kan leda till skada eller död av hjärtvävnaden, eftersom det drabbade området berövas syre och näringsämnen. Riskfaktorer inkluderar åderförkalkning, som kännetecknas av ansamlingen av lipidrika skumceller som bildar plack, vilket slutligen förtränger artärerna och ökar risken för hjärt-kärlhändelser.",
                    "simplified": "En hjärtinfarkt, eller hjärtinfarkt, inträffar när blodflödet till en del av hjärtat blockeras, ofta av sprucken plack i kranskärlen, vilket kan skada eller döda hjärtvävnaden på grund av brist på syre och näringsämnen, med åderförkalkning som en stor bidragande faktor.",
                    "example": "Föreställ dig en 55-årig man vid namn Tom som har känt sig ovanligt trött och har en tryckande känsla i bröstet medan han joggar. Efter ett besök hos läkaren får han veta att han har en plackuppbyggnad i artärerna på grund av åderförkalkning, vilket ökar risken för en hjärtinfarkt. En dag, när han anstränger sig under sin morgonlöpning, spricker en plack, vilket blockerar blodflödet till hjärtat och leder till en hjärtinfarkt, vilket understryker vikten av att övervaka hjärthälsa och livsstilsval."
                },
                {
                    "startIdx": 1039,
                    "endIdx": 1056,
                    "keyword": "hjärt-kärl",
                    "definition": "Begreppet \"hjärt-kärl\" avser systemet av hjärtat (cardio) och blodkärl (vaskulär), som omfattar de organ och vävnader som är involverade i blodcirkulationen i hela kroppen. Hjärt-kärlsjukdomar, såsom kranskärlssjukdom, uppstår från tillstånd som försämrar blodflödet, vilket ofta leder till ischemia, angina pectoris eller andra allvarliga komplikationer. Även om risken för att utveckla hjärt-kärlsjukdomar ökar med åldern, påverkas den av olika påverkbara faktorer, vilket understryker vikten av livsstilsval för förebyggande.",
                    "simplified": "Begreppet \"hjärt-kärl\" avser hjärtat och blodkärlen, med fokus på nätverket som ansvarar för att cirkulera blod i kroppen, och inkluderar sjukdomar som stör denna cirkulation, ofta kopplade till livsstilsfaktorer som kan förändras för att minska risken.",
                    "example": "Föreställ dig en medelålders man vid namn Tom som tycker om att jogga men har nyligen börjat uppleva bröstsmärta efter att ha sprungit en viss sträcka. Denna smärta är ett tecken på angina pectoris, vilket indikerar att hans hjärta kämpar för att få tillräckligt med blod på grund av förträngda artärer – ett vanligt problem vid hjärt-kärlsjukdom. Genom att göra hälsosammare val, som att förbättra sin kost och sluta röka, kan Tom avsevärt minska risken för att hans hjärt-kärlhälsa försämras och njuta av en mer aktiv livsstil."
                },
                {
                    "startIdx": 1278,
                    "endIdx": 1292,
                    "keyword": "högt blodtryck",
                    "definition": "Högt blodtryck, eller hypertoni, är ett kroniskt medicinskt tillstånd som kännetecknas av konsekvent förhöjt arteriellt tryck, vilket kan leda till betydande hjärt-kärlkomplikationer om det inte hanteras. Det påverkas ofta av faktorer som kost, livsstil och genetik, och behandlas vanligtvis med olika mediciner som syftar till att minska belastningen på hjärtat och artärerna. Att hantera högt blodtryck är avgörande för att förebygga allvarligare hälsoproblem, såsom hjärtinfarkt och stroke, genom att upprätthålla ett lämpligt blodflöde och minimera hjärt-kärlrisker.",
                    "simplified": "Högt blodtryck, även känt som hypertoni, är ett långvarigt tillstånd där blodets kraft mot artärväggarna är konsekvent för hög, vilket potentiellt kan leda till allvarliga hjärtproblem om det inte hanteras ordentligt genom livsstilsförändringar och mediciner.",
                    "example": "Föreställ dig en upptagen yrkesperson, Alex, som ofta hoppar över måltider och förlitar sig på koffein för att klara dagen. Efter en rutinmässig hälsokontroll upptäcker Alex att deras blodtryck är högre än normalt, vilket ökar risken för allvarliga hjärtproblem. Genom att göra kostförändringar, som att äta mer frukt och grönsaker, och ta föreskrivna mediciner kan Alex hantera sitt höga blodtryck och minska risken för framtida hjärtinfarkt eller stroke."
                },
                {
                    "startIdx": 1304,
                    "endIdx": 1314,
                    "keyword": "kolesterol",
                    "definition": "Kolesterol är ett vaxliknande, fettliknande ämne som finns i varje cell i kroppen och är avgörande för produktion av hormoner, vitamin D och ämnen som hjälper till med matsmältningen. Det transporteras genom blodomloppet av lipoproteiner, där lågdensitetslipoprotein (LDL) ofta kallas \"dåligt kolesterol\" på grund av dess benägenhet att ackumuleras i artärväggarna, vilket ökar risken för hjärt-kärlsjukdomar som hjärtinfarkt och stroke. Att övervaka och hantera kolesterolnivåer är avgörande, särskilt i samband med andra riskfaktorer som fluktuerande blodtryck, för att förhindra långsiktiga hälsokomplikationer.",
                    "simplified": "Kolesterol är ett fettliknande ämne som finns i alla kroppens celler och är viktigt för hormonproduktion och matsmältning, men höga nivåer, särskilt av lågdensitetslipoprotein (LDL), kan leda till förträngning av artärer och öka risken för hjärtsjukdomar och stroke, vilket gör det viktigt att övervaka tillsammans med andra hälsomarkörer som blodtryck.",
                    "example": "Föreställ dig att du är på en rutinmässig hälsokontroll och din läkare berättar att dina kolesterolnivåer är högre än normalt. De förklarar att för mycket \"dåligt\" LDL-kolesterol kan täppa till dina artärer, likt hur fett kan byggas upp i ett rör, vilket kan leda till allvarliga problem som hjärtinfarkt. Genom att göra några kostförändringar och motionera mer kan du hjälpa till att sänka dina kolesterolnivåer och hålla ditt hjärta friskt!"
                }
        
            ]
        
        }
    }
}


export default medicalData;
