// src/App.js

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Hero from './components/Hero';
import WhySibel from './components/WhySibel';
import ContactUs from './components/ContactUs';
import Sidebar from './components/Sidebar';
import Footer from './components/Footer';
import OurTeam from './components/OurTeam';
import LearnMore from './components/LearnMore';
import Demo from './components/Demo'; // Import the Demo component
import Login from './pages/Login'; // Import the Login component
import ForgotPassword from './pages/ForgotPassword'; // Import ForgotPassword component (if exists)
import AuthFinanceGlossary from './pages/auth_glossary'; //Temp for glossary
import './App.css';

const App = () => {
  const [scrolled, setScrolled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 100);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Router>
      <div className="App">
        <Header scrolled={scrolled} toggle={toggleSidebar} isOpen={isOpen} />
        <Sidebar isOpen={isOpen} toggle={toggleSidebar} />
        <main id="main-content">
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <Hero />
                  <Demo /> {/* Include the Demo component here */}
                  <WhySibel />
                  <ContactUs />
                </>
              }
            />
            <Route path="/team" element={<OurTeam />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/learn-more" element={<LearnMore />} />
            <Route path="/login" element={<Login />} /> {/* Added Login Route */}
            <Route path="/forgot-password" element={<ForgotPassword />} /> {/* Added ForgotPassword Route */}
            <Route path="/auth_access_glossary" element={<AuthFinanceGlossary />} />
            {/* Add more routes as needed */}
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
