import React from 'react';
import { Link } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';
import './Sidebar.css';

const Sidebar = ({ isOpen, toggle }) => {
  return (
    <div className={`sidebar ${isOpen ? 'open' : ''}`} aria-hidden={!isOpen}>
      <div className="sidebar-content">
        <div className="sidebar-header">
          <button
            className="close-icon"
            onClick={toggle}
            aria-label="Close Sidebar"
          >
            <FaTimes />
          </button>
        </div>
        <ul className="sidebar-menu">
          <li><Link to="/" onClick={toggle}>Home</Link></li>
          <li><Link to="/team" onClick={toggle}>Our Team</Link></li>
          <li><Link to="/learn-more" onClick={toggle}>Learn More</Link></li>
        </ul>
        <div className="sidebar-footer">
          <hr className="footer-divider" />
          <Link to="/login" className="login-button" onClick={toggle}>
            Login
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
