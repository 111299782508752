const legalData = {
    "originalText": "The Chevron doctrine arose from the Court's 1984 decision in Chevron U.S.A. Inc. v. Natural Resources Defense Council, Inc., 467 U.S. 837 (1984). That decision requires courts to give deference to a reasonable interpretation of an ambiguous statute by a government agency.\n\n\n\nBut it was not until 2011, in Mayo Foundation for Medical Education and Research, 562 U.S. 44 (2011), that the justices resolved a split in the lower courts and held that Chevron deference applied to interpretive tax regulations issued under Sec. 7805(a), which gives a general grant of authority to Treasury to \"prescribe all needful rules and regulations for enforcement\" of the Code. Before that decision, courts had generally given a lower level of deference to Treasury's interpretive regulations.\n\n\n \nIn January, the Court heard two cases — Loper Bright Enterprises, Inc. v. Raimondo, No. 22-451 (U.S. 1/17/24) (oral argument), and Relentless, Inc. v. Department of Commerce, No. 22-1219 (U.S. 1/17/24) (oral argument) — involving a National Marine Fisheries Service regulation that requires the herring industry to pay for observers on fishing boats. Both the D.C. Circuit and the First Circuit, respectively, applying the Chevron doctrine, upheld the rule.",
    "translations": {
        "gb": { 
            "translatedText": "The Chevron doctrine arose from the Court's 1984 decision in Chevron U.S.A. Inc. v. Natural Resources Defense Council, Inc., 467 U.S. 837 (1984). That decision requires courts to give deference to a reasonable interpretation of an ambiguous statute by a government agency.\n\n\n\nBut it was not until 2011, in Mayo Foundation for Medical Education and Research, 562 U.S. 44 (2011), that the justices resolved a split in the lower courts and held that Chevron deference applied to interpretive tax regulations issued under Sec. 7805(a), which gives a general grant of authority to Treasury to \"prescribe all needful rules and regulations for enforcement\" of the Code. Before that decision, courts had generally given a lower level of deference to Treasury's interpretive regulations.\n\n\n \nIn January, the Court heard two cases — Loper Bright Enterprises, Inc. v. Raimondo, No. 22-451 (U.S. 1/17/24) (oral argument), and Relentless, Inc. v. Department of Commerce, No. 22-1219 (U.S. 1/17/24) (oral argument) — involving a National Marine Fisheries Service regulation that requires the herring industry to pay for observers on fishing boats. Both the D.C. Circuit and the First Circuit, respectively, applying the Chevron doctrine, upheld the rule.",
            "highlights": [
                {
                    "startIdx": 4,
                    "endIdx": 20,
                    "keyword": "Chevron doctrine",
                    "definition": "Chevron deference or Chevron doctrine is a legal doctrine established by the U.S. Supreme Court in *Chevron U.S.A., Inc. v. Natural Resources Defense Council, Inc.* (1984), which mandates that courts should defer to the interpretations of ambiguous statutes made by federal administrative agencies, provided that the agency's interpretation is reasonable and within its authority. This principle is particularly relevant in cases where courts are faced with interpretive tax regulations or other agency rules that have not been previously adjudicated, thereby allowing agencies to exercise expertise in their regulatory domains. The application of Chevron deference effectively acknowledges the specialized knowledge of administrative agencies in navigating complex statutory frameworks, while also promoting consistency in regulatory interpretation.",
                    "simplified": "Chevron deference is a legal principle that requires courts to respect and rely on the reasonable interpretations of unclear laws made by federal agencies, recognizing their expertise in specific regulatory matters.",
                    "example": "Imagine a federal agency, like the IRS, issues a new regulation about tax deductions that isn't clearly defined in the law. If a business challenges this regulation in court, the judge may use Chevron deference to accept the IRS's interpretation as long as it seems reasonable and the agency is acting within its legal authority. This way, the court recognizes the IRS's expertise in tax matters, helping to ensure consistent application of complex tax laws."
                },
                {
                    "startIdx": 49,
                    "endIdx": 57,
                    "keyword": "decision",
                    "definition": "In a legal context, a \"decision\" refers to a formal conclusion reached by a court or judicial authority regarding a legal question or dispute, which may involve the interpretation of laws or the adjudication of rights and responsibilities among parties. The Chevron doctrine, established in the 1984 case Chevron U.S.A. Inc. v. Natural Resources Defense Council, underscores the principle that courts should defer to administrative agencies' interpretations of ambiguous statutes they are tasked with enforcing, provided those interpretations are reasonable. This decision effectively shaped the relationship between judicial review and agency discretion, influencing regulatory practices and legal precedents.",
                    "simplified": "In legal terms, a \"decision\" is the official resolution made by a court on a legal issue, often determining how laws apply to specific cases or disputes, as exemplified by the Chevron doctrine, which emphasizes that courts should generally respect reasonable interpretations by administrative agencies of unclear laws they enforce.",
                    "example": "Imagine a company wants to build a factory but is unsure if their plans comply with environmental laws. They seek guidance from an administrative agency, which interprets the law in a specific way. Following the Chevron decision, if a court reviews this agency's interpretation and finds it reasonable, it will likely side with the agency, allowing the factory to proceed under those rules."
                },
                {
                    "startIdx": 61,
                    "endIdx": 137,
                    "keyword": "Chevron U.S.A. Inc. v. Natural Resources Defense Council, Inc., 467 U.S. 837 (1984)",
                    "definition": "The landmark Supreme Court case \"Chevron U.S.A. Inc. v. Natural Resources Defense Council, Inc., 467 U.S. 837 (1984)\" established the legal doctrine known as 'Chevron deference.' This doctrine directs courts to defer to a federal agency's interpretation of a statute it administers if the statute is ambiguous and the agency's interpretation is reasonable. The case arose from a dispute over the Environmental Protection Agency's (EPA) interpretation of the Clean Air Act. Chevron deference has since become a foundational principle in administrative law, affecting how courts review agency regulations and decisions.",
                    "simplified": "In the Supreme Court case \"Chevron U.S.A. Inc. v. Natural Resources Defense Council, Inc., 467 U.S. 837 (1984),\" the Court ruled that if a law is unclear, courts should generally trust a government agency's reasonable interpretation of that law. This decision, known as Chevron deference, has a major impact on how courts handle disputes involving government regulations.",
                    "example": "Imagine a situation where the Environmental Protection Agency (EPA) creates a new rule under the Clean Air Act. If someone challenges the rule in court, the court may apply Chevron deference, meaning it will likely side with the EPA's interpretation of the law as long as it is reasonable. This doctrine helps agencies like the EPA enforce laws more effectively, but it also raises questions about the extent of their power."
                },
                {
                    "startIdx": 151,
                    "endIdx": 159,
                    "keyword": "decision",
                    "definition": "In a legal context, a \"decision\" refers to the conclusion reached by a court or judicial body after deliberation, which often involves interpreting laws, assessing evidence, and applying relevant legal standards to the facts of a case. The term embodies the principles of deference to reasonable assumptions, such as the presumption of innocence in criminal trials, where the burden of proof lies with the prosecution to establish guilt beyond a reasonable doubt. Notably, decisions also reflect the application of standards like \"due care\" in negligence law, which requires jurors to consider the conduct of a reasonable person in determining liability.",
                    "simplified": "In legal terms, a \"decision\" is the resolution made by a court after considering laws and evidence, often involving the application of principles like the presumption of innocence and standards of reasonable conduct in assessing liability.",
                    "example": "Imagine a case where a driver gets into an accident while texting. The court's decision will hinge on whether the driver exercised \"due care\" by considering what a reasonable person would do in that situation—like not using a phone while driving. If the jury believes the driver was negligent, their decision could lead to the driver being held liable for damages."
                },
                {
                    "startIdx": 199,
                    "endIdx": 209,
                    "keyword": "reasonable",
                    "definition": "The term \"reasonable,\" in the context of judicial interpretation, refers to a standard applied by courts that acknowledges the need for flexibility and discretion when interpreting ambiguous statutes. This standard allows judges to defer to interpretations that are sensible and justifiable, especially in cases of first impression where existing precedents may not provide clear guidance. Thus, a \"reasonable\" interpretation is one that aligns with the intent of the law while considering the broader implications and context of legal principles.",
                    "simplified": "In legal terms, \"reasonable\" denotes a flexible standard that courts use to interpret unclear laws, favoring interpretations that are logical and justifiable, particularly in unique cases without prior precedent.",
                    "example": "Imagine a new law is passed that aims to improve online privacy, but it uses vague language about what constitutes \"personal data.\" If a company is sued for how they handled user information, a judge might look at the law and decide what's a \"reasonable\" interpretation of that term, considering the law's goal to protect privacy and the common understanding of personal data. This allows the court to apply the law fairly, even though there are no previous cases to guide them directly."
                },
                {
                    "startIdx": 241,
                    "endIdx": 248,
                    "keyword": "statute",
                    "definition": "A statute is a formal written law enacted by a legislative body at the federal, state, or local level, which establishes specific legal obligations or rights. In the context of legal interpretation, agencies may provide a reasonable interpretation of ambiguous statutes to clarify their application, reflecting the principle that laws must be accessible and understandable to those governed by them. Furthermore, statutes can be challenged in court if they are deemed vague or unconstitutional, as this can impede an individual's ability to understand the law or defend against charges.",
                    "simplified": "A statute is a formal law created by a governing body that outlines specific rights or responsibilities, and if its language is unclear, agencies may interpret it to ensure it is understandable, while also allowing for legal challenges if the statute is too vague or violates constitutional rights.",
                    "example": "Imagine a local city passes a statute that prohibits \"loud noises\" after 10 PM, but it doesn't clearly define what \"loud\" means. If a neighbor is fined for playing music at a reasonable volume during a gathering, they could challenge the statute's vagueness in court, arguing that it’s unconstitutional because it doesn’t allow an average person to understand what is considered a violation. In this way, the interpretation of the statute becomes crucial for fair enforcement and understanding of the law."
                },
                {
                    "startIdx": 254,
                    "endIdx": 264,
                    "keyword": "government",
                    "definition": "The term \"government\" refers to the organized system through which a community or political entity is governed, encompassing the institutions and processes that administer public policy, enforce laws, and manage public resources. In the context of statutory interpretation, a government agency's role involves interpreting ambiguous laws to ensure they are applied consistently and justly, often drawing on precedents and principles from previous cases to guide their decisions. This interpretation is crucial in maintaining the rule of law and protecting the rights of individuals against potential governmental overreach or negligence.",
                    "simplified": "The term \"government\" denotes the structured framework through which a community or political body is managed, including the bodies and methods that create and enforce laws and policies, with government agencies tasked with clarifying unclear laws to ensure fair application and uphold individual rights.",
                    "example": "Imagine a situation where a new law is passed regarding the use of drones in urban areas, but the language is vague about \"commercial use.\" A government agency, tasked with enforcing this law, steps in to interpret what \"commercial use\" means—perhaps deciding that it includes delivery services but not recreational flying. This interpretation helps ensure that the law is applied fairly and consistently, preventing confusion and potential misuse while protecting the rights of both drone operators and the public."
                },
                {
                    "startIdx": 265,
                    "endIdx": 271,
                    "keyword": "agency",
                    "definition": "In this context, \"agency\" refers to a governmental body or organization empowered to implement and enforce specific statutes and regulations. Such agencies possess the authority to interpret ambiguous statutes, which can involve making determinations regarding how laws should be applied in practice, particularly in cases that have not been previously adjudicated. The interpretation provided by agencies is often significant in shaping legal precedents and can influence subsequent judicial decisions, as seen in cases like Mayo Foundation.",
                    "simplified": "In this context, \"agency\" denotes a governmental organization that has the authority to apply and clarify laws, particularly in situations where the legal text is unclear, impacting future legal interpretations and rulings.",
                    "example": "Imagine a government agency, like the Environmental Protection Agency (EPA), faced with a new law about air quality standards that isn't very clear. They might interpret this ambiguous statute to mean that certain factories need to reduce their emissions more than previously thought, which could lead to stricter regulations and fines for those that don’t comply. This interpretation not only helps clarify the law but also sets a precedent for how similar cases will be handled in the future."
                },
                {
                    "startIdx": 306,
                    "endIdx": 356,
                    "keyword": "Mayo Foundation",
                    "definition": "\"Mayo Foundation\" as it pertains to the Mayo Foundation for Medical Education and Research case, it refers to an organization or institution established for a specific purpose, often related to education, research, or charitable activities. The case highlighted issues surrounding governmental immunity from lawsuits, illustrating the complexities in holding public entities accountable for negligence, particularly in the realm of medical and educational institutions. The implications of this doctrine reflect longstanding principles of English common law that protect government entities, thereby raising concerns about justice and accountability in public services.",
                    "simplified": " \"Mayo Foundation\" denotes an organization created for a particular mission, typically involving education, research, or charity, and the case underscores the challenges of holding government entities responsible for negligence in these sectors.",
                    "example": "Imagine a scenario where a public hospital, funded by the Mayo Foundation, makes a serious error during a medical procedure. Because of the legal doctrine of governmental immunity, patients harmed by this mistake might struggle to hold the hospital accountable in court, sparking debates about the fairness of allowing public institutions to operate without fear of lawsuits. This situation highlights the importance of understanding how entities like the Mayo Foundation, as legal entities, can impact access to justice and accountability in healthcare."
                },
                {
                    "startIdx": 447,
                    "endIdx": 464,
                    "keyword": "chevron deference",
                    "definition": "Chevron deference is a legal doctrine established by the U.S. Supreme Court in *Chevron U.S.A., Inc. v. Natural Resources Defense Council, Inc.* (1984), which mandates that courts should defer to the interpretations of ambiguous statutes made by federal administrative agencies, provided that the agency's interpretation is reasonable and within its authority. This principle is particularly relevant in cases where courts are faced with interpretive tax regulations or other agency rules that have not been previously adjudicated, thereby allowing agencies to exercise expertise in their regulatory domains. The application of Chevron deference effectively acknowledges the specialized knowledge of administrative agencies in navigating complex statutory frameworks, while also promoting consistency in regulatory interpretation.",
                    "simplified": "Chevron deference is a legal principle that requires courts to respect and rely on the reasonable interpretations of unclear laws made by federal agencies, recognizing their expertise in specific regulatory matters.",
                    "example": "Imagine a federal agency, like the IRS, issues a new regulation about tax deductions that isn't clearly defined in the law. If a business challenges this regulation in court, the judge may use Chevron deference to accept the IRS's interpretation as long as it seems reasonable and the agency is acting within its legal authority. This way, the court recognizes the IRS's expertise in tax matters, helping to ensure consistent application of complex tax laws."
                },
                {
                    "startIdx": 518,
                    "endIdx": 530,
                    "keyword": "Sec. 7805(a)",
                    "definition": "Section 7805(a) of the Internal Revenue Code grants the Secretary of the Treasury the authority to prescribe all needful rules and regulations for the enforcement of the Internal Revenue laws. This provision gives the Treasury Department broad discretion in interpreting and implementing tax laws, including the issuance of regulations and guidance. Legal disputes often arise over the extent of this authority, particularly when regulations issued under Sec. 7805(a) are challenged as overreaching or inconsistent with the statute's intent.",
                    "simplified": "Section 7805(a) gives the Treasury Department the power to create rules and regulations needed to enforce tax laws. Sometimes, these regulations are challenged in court if they are seen as going beyond what the law allows.",
                    "example": "Consider a situation where the Treasury Department issues a new tax regulation under Sec. 7805(a) that changes how certain deductions are calculated. A taxpayer might challenge this regulation, arguing that it contradicts the original intent of the tax law. The court's decision could impact how much freedom the Treasury has in interpreting tax laws and setting regulations, affecting taxpayers nationwide."
                },                
                {
                    "startIdx": 621,
                    "endIdx": 632,
                    "keyword": "regulations",
                    "definition": "Regulations are specific rules and administrative codes established by governmental agencies at various levels, including municipal, county, state, and federal, to ensure compliance with broader statutory laws. Although they are not legislated laws themselves, regulations carry legal weight as they are enacted under the authority of statutes and often prescribe penalties for non-compliance. In the context of the Treasury's authority to \"prescribe all needful rules and regulations for enforcement,\" these regulations facilitate the implementation and enforcement of the governing laws or codes.",
                    "simplified": "Regulations are detailed rules created by government agencies at different levels to ensure that broader laws are followed, and while they are not laws themselves, they are legally binding and can impose penalties for violations.",
                    "example": "Imagine a new environmental law is passed to reduce air pollution. To enforce this law, the Environmental Protection Agency (EPA) creates regulations that specify how much pollution factories can emit and the penalties for those that exceed the limits. These regulations ensure that the law is effectively implemented and that businesses understand the rules they must follow to avoid fines."
                },
                {
                    "startIdx": 657,
                    "endIdx": 661,
                    "keyword": "code",
                    "definition": "In the context provided, \"code\" refers to a systematic collection of rules, regulations, and laws that govern behavior and procedures within specific jurisdictions, including municipal, county, state, and federal levels. While codes themselves may not be laws in the strictest sense, they hold legal authority due to their derivation from statutory enactments, often carrying penalties for noncompliance. Furthermore, the term underscores the framework within which judges operate, allowing for discretionary decision-making that aligns with established codes while still subject to appellate review.",
                    "simplified": "In this context, \"code\" denotes an organized set of guidelines and regulations that dictate conduct and procedures across various levels of government, which, while not always classified as laws, possess legal weight because they stem from statutory authority and may impose penalties for violations, influencing judicial discretion and appellate oversight.",
                    "example": "Imagine a city has a building code that requires all new constructions to meet specific safety standards, like having fire exits and proper electrical wiring. If a contractor ignores these regulations and builds a structure that doesn't comply, the city can impose fines or even halt the project until it meets the code requirements. This ensures that all buildings are safe for residents, demonstrating how codes play a crucial role in protecting public welfare."
                },
                {
                    "startIdx": 742,
                    "endIdx": 750,
                    "keyword": "Department of the Treasury",
                    "definition": "The Department of the Treasury is a federal executive department responsible for managing the government's finances, including collecting revenue, paying bills, managing federal finances, and producing currency. It plays a critical role in formulating and implementing economic policy, overseeing the Internal Revenue Service (IRS), and enforcing federal finance and tax laws. Legal cases involving the Department of the Treasury often address issues related to taxation, financial regulation, and economic sanctions.",
                    "simplified": "The Department of the Treasury is a federal agency that handles the government's money, including collecting taxes and enforcing tax laws. It also helps shape the country's economic policies.",
                    "example": "Consider a scenario where a business challenges a new tax regulation issued by the Department of the Treasury, arguing that it unfairly increases their tax burden. The court's decision could influence how the Treasury enforces tax laws and impacts the broader economy, reflecting the department's significant role in financial regulation."
                },                
                {
                    "startIdx": 823,
                    "endIdx": 877,
                    "keyword": "Loper Bright Enterprises, Inc. v. Raimondo, No. 22-451",
                    "definition": " \"Loper Bright Enterprises, Inc. v. Raimondo, No. 22-451,\" the case involves a dispute between a private enterprise and the government over regulatory authority and the interpretation of administrative law. This case centers on the extent of power that federal agencies possess in interpreting statutes that impact businesses, particularly in industries subject to heavy regulation. The decision in this case could have far-reaching implications for how much deference courts give to agency interpretations of the law, potentially altering the balance of power between the government and private entities.",
                    "simplified": " \"Loper Bright Enterprises, Inc. v. Raimondo, No. 22-451,\" the focus is on how much authority federal agencies have to interpret laws that affect businesses, especially in heavily regulated industries. The outcome could change how courts view agency power, affecting the relationship between the government and private businesses.",
                    "example": "Consider a scenario where a fishing company, like Loper Bright Enterprises, disagrees with a new federal regulation imposed by an agency. The company argues that the agency overstepped its authority in interpreting the law. If the court sides with the company, it could lead to reduced power for agencies in setting regulations, impacting how businesses are governed across various sectors. This case highlights the ongoing debate over the scope of government power in regulating industries and the importance of judicial oversight in maintaining the balance between public interests and private rights."
                },
                {
                    "startIdx": 894,
                    "endIdx": 907,
                    "keyword": "oral argument",
                    "definition": "An oral argument is a formal presentation made by attorneys before a court, wherein they articulate their case and respond to questions posed by the judges. This process allows the court to clarify points of law and fact, facilitating a more informed decision-making process regarding the appeal, as seen in cases like *ses, Inc. v. Raimondo*. The opportunity for oral argument underscores the importance of direct dialogue in the judicial review process, distinguishing it from written briefs submitted prior to the hearing.",
                    "simplified": "An oral argument is a structured discussion where lawyers present their case to a court and answer judges' questions, helping to clarify legal issues and facts to assist in the appellate decision-making process.",
                    "example": "Imagine a situation where a company believes it was unfairly taxed due to a misclassification of its products. During the oral argument in *ses, Inc. v. Raimondo*, the attorneys present their case to the judges, explaining why the tax classification should be changed while also answering challenging questions from the judges about the law and evidence. This direct interaction helps the judges grasp the complexities of the case, ensuring they can make a more informed decision when reviewing the appeal."
                },                            
                {
                    "startIdx": 914,
                    "endIdx": 969,
                    "keyword": "Relentless, Inc. v. Department of Commerce, No. 22-1219",
                    "definition": "In the legal case \"Relentless, Inc. v. Department of Commerce, No. 22-1219,\" the dispute involves the regulatory authority of the Department of Commerce over commercial fishing practices. The case centers on the interpretation of federal laws governing marine resources, with Relentless, Inc. challenging the Department's regulations as overly restrictive and harmful to its business operations. The outcome of this case could have significant implications for the balance between environmental conservation efforts and the economic interests of the fishing industry.",
                    "simplified": "In the case \"Relentless, Inc. v. Department of Commerce, No. 22-1219,\" the issue is about whether the Department of Commerce's fishing regulations are too strict and negatively impact the fishing industry. The case could affect how much control the government has over fishing practices versus protecting the environment.",
                    "example": "Imagine a scenario where a fishing company like Relentless, Inc. is required to follow new regulations that limit how much fish they can catch to protect certain species. The company argues that these rules are too harsh and hurt their business. If the court sides with the company, it might lead to changes in how the government enforces fishing regulations, potentially easing restrictions on the industry while raising concerns about environmental protection."
                },
                                
                {
                    "startIdx": 1015,
                    "endIdx": 1048,
                    "keyword": "National Marine Fisheries Service",
                    "definition": "The National Marine Fisheries Service (NMFS) is a federal agency responsible for the stewardship of the nation's ocean resources and their habitat. In legal contexts, particularly in cases like Loper Bright Enterprises, Inc. v. Raimondo, the NMFS often plays a central role due to its regulatory authority over marine fisheries and the enforcement of laws such as the Magnuson-Stevens Fishery Conservation and Management Act. The agency's decisions can have significant economic and environmental impacts, leading to disputes over the scope of its regulatory power and its interpretation of federal statutes.",
                    "simplified": "The National Marine Fisheries Service (NMFS) is a federal agency that manages the country's ocean resources. It regulates fishing and enforces related laws, which sometimes leads to legal disputes about how much power it has and how it interprets these laws.",
                    "example": "Imagine a scenario where the National Marine Fisheries Service imposes strict fishing quotas to protect a certain fish species. A fishing company might challenge these quotas in court, arguing that the agency overstepped its authority. The outcome of such a case could influence how the NMFS regulates the fishing industry in the future, affecting both environmental protection efforts and the economic interests of fishing communities."
                },    
                {
                    "startIdx": 1143,
                    "endIdx": 1155,
                    "keyword": "D.C. Circuit",
                    "definition": "The D.C. Circuit, formally known as the United States Court of Appeals for the District of Columbia Circuit, is one of the most influential federal appellate courts in the United States. It has jurisdiction over cases arising in the District of Columbia and is particularly known for hearing cases involving federal regulations, administrative law, and issues involving federal agencies. Decisions from the D.C. Circuit can have significant implications nationwide, as many cases involve challenges to federal policies and regulations that affect the entire country.",
                    "simplified": "The D.C. Circuit is a powerful federal appeals court that often deals with cases involving government regulations and federal agencies. Its decisions can have a big impact across the country because they often involve important federal policies.",
                    "example": "Imagine a case where a new environmental regulation is challenged by a business in the D.C. Circuit. The court's decision on whether the regulation is legal could set a precedent that affects similar regulations nationwide. This shows the D.C. Circuit's crucial role in shaping how federal laws and policies are applied across the United States."
                },
                {
                    "startIdx": 1164,
                    "endIdx": 1177,
                    "keyword": "D.C. Circuit",
                    "definition": "The First Circuit, formally known as the United States Court of Appeals for the First Circuit, is a federal appellate court with jurisdiction over cases from the states of Maine, Massachusetts, New Hampshire, Rhode Island, and Puerto Rico. The First Circuit hears appeals from federal district courts within its jurisdiction and is known for its rulings on a wide range of legal issues, including constitutional law, civil rights, and commercial disputes. Although smaller in scope compared to other circuits, the First Circuit's decisions can have a significant impact on the legal landscape in its region.",
                    "simplified": "The First Circuit is a federal appeals court that covers Maine, Massachusetts, New Hampshire, Rhode Island, and Puerto Rico. It handles appeals on various legal issues and its decisions affect the laws and regulations in these states and territories.",
                    "example": "Consider a case where a business in Massachusetts appeals a federal court ruling to the First Circuit, arguing that a new labor law violates their rights. The First Circuit's decision could influence how similar laws are applied throughout the region, shaping the legal standards that businesses and individuals must follow in the states and territories under its jurisdiction."
                },                                
                {
                    "startIdx": 1206,
                    "endIdx": 1222,
                    "keyword": "Chevron doctrine",
                    "definition": "Chevron deference or Chevron doctrine is a legal doctrine established by the U.S. Supreme Court in *Chevron U.S.A., Inc. v. Natural Resources Defense Council, Inc.* (1984), which mandates that courts should defer to the interpretations of ambiguous statutes made by federal administrative agencies, provided that the agency's interpretation is reasonable and within its authority. This principle is particularly relevant in cases where courts are faced with interpretive tax regulations or other agency rules that have not been previously adjudicated, thereby allowing agencies to exercise expertise in their regulatory domains. The application of Chevron deference effectively acknowledges the specialized knowledge of administrative agencies in navigating complex statutory frameworks, while also promoting consistency in regulatory interpretation.",
                    "simplified": "Chevron deference is a legal principle that requires courts to respect and rely on the reasonable interpretations of unclear laws made by federal agencies, recognizing their expertise in specific regulatory matters.",
                    "example": "Imagine a federal agency, like the IRS, issues a new regulation about tax deductions that isn't clearly defined in the law. If a business challenges this regulation in court, the judge may use Chevron deference to accept the IRS's interpretation as long as it seems reasonable and the agency is acting within its legal authority. This way, the court recognizes the IRS's expertise in tax matters, helping to ensure consistent application of complex tax laws."
                }
            ]
        },
        "se": {
            "translatedText": "Chevron-doktrinen uppstod från Högsta domstolens beslut 1984 i målet Chevron U.S.A. Inc. mot Natural Resources Defense Council, Inc., 467 U.S. 837 (1984). Det beslutet kräver att domstolar ska ge hänsyn till en rimlig tolkning av en tvetydig lagstiftning av en statlig myndighet.\n\nMen det var först 2011, i målet Mayo Foundation for Medical Education and Research, 562 U.S. 44 (2011), som domarna löste en splittring i lägre domstolar och fastställde att Chevron-hänsyn gällde för tolkande skatteregler utfärdade enligt Sec. 7805(a), som ger en allmän befogenhet till Finansdepartementet att \"föreskriva alla nödvändiga regler och förordningar för verkställighet\" av koden. Innan det beslutet hade domstolar generellt gett en lägre nivå av hänsyn till Finansdepartementets tolkande regler.\n\nI januari hörde domstolen två fall – Loper Bright Enterprises, Inc. mot Raimondo, No. 22-451 (U.S. 1/17/24) (muntlig argumentation), och Relentless, Inc. mot Department of Commerce, No. 22-1219 (U.S. 1/17/24) (muntlig argumentation) – som involverade en reglering från National Marine Fisheries Service som kräver att sillindustrin betalar för observatörer på fiskebåtar. Både D.C. Circuit och First Circuit, som tillämpade Chevron-doktrinen, bekräftade regeln.",
            "highlights": [
                {
                    "startIdx": 0,
                    "endIdx": 17,
                    "keyword": "Chevron-doktrinen",
                    "definition": "Chevron-deferens eller Chevron-doktrinen är en juridisk doktrin som etablerades av den amerikanska högsta domstolen i *Chevron U.S.A., Inc. v. Natural Resources Defense Council, Inc.* (1984), som föreskriver att domstolar bör respektera och ge hänsyn till tolkningar av tvetydiga lagar som görs av federala administrativa myndigheter, förutsatt att myndighetens tolkning är rimlig och inom dess befogenhet. Denna princip är särskilt relevant i fall där domstolar ställs inför tolkande skatteregler eller andra myndighetsregler som inte tidigare prövats, vilket möjliggör för myndigheter att utöva expertis inom sina regulatoriska områden. Tillämpningen av Chevron-deferens erkänner effektivt de administrativa myndigheternas specialkunskaper i att navigera genom komplexa lagstiftningsramverk, samtidigt som den främjar konsekvens i regulatorisk tolkning.",
                    "simplified": "Chevron-deferens är en juridisk princip som kräver att domstolar ska respektera och förlita sig på rimliga tolkningar av oklara lagar som görs av federala myndigheter, och erkänner deras expertis inom specifika regulatoriska frågor.",
                    "example": "Föreställ dig att en federal myndighet, som IRS, utfärdar en ny föreskrift om skatteavdrag som inte är klart definierad i lagen. Om ett företag ifrågasätter denna föreskrift i domstol, kan domaren använda Chevron-deferens för att acceptera IRS:s tolkning så länge den verkar rimlig och myndigheten agerar inom sin lagliga befogenhet. På detta sätt erkänner domstolen IRS:s expertis i skattefrågor, vilket hjälper till att säkerställa konsekvent tillämpning av komplexa skattelagar."
                },

                {
                    "startIdx": 49,
                    "endIdx": 55,
                    "keyword": "beslut",
                    "definition": "I en juridisk kontext avser ett \"beslut\" en formell slutsats som nås av en domstol eller rättslig myndighet angående en juridisk fråga eller tvist, som kan involvera tolkning av lagar eller bedömning av rättigheter och ansvar mellan parter. Chevron-doktrinen, som etablerades i fallet Chevron U.S.A. Inc. v. Natural Resources Defense Council, betonar principen att domstolar bör ge hänsyn till administrativa myndigheters tolkningar av tvetydiga lagar de är ansvariga för att genomföra, förutsatt att dessa tolkningar är rimliga. Detta beslut formade effektivt relationen mellan rättslig prövning och myndighetsdiskretion, vilket påverkar regulatoriska praxis och juridiska prejudikat.",
                    "simplified": "I juridiska termer är ett \"beslut\" den officiella resolutionen som görs av en domstol i en juridisk fråga, ofta genom att bestämma hur lagar ska tillämpas på specifika fall eller tvister, som exemplifieras av Chevron-doktrinen, som betonar att domstolar generellt bör respektera rimliga tolkningar gjorda av administrativa myndigheter av oklara lagar de genomför.",
                    "example": "Föreställ dig att ett företag vill bygga en fabrik men är osäker på om deras planer följer miljölagarna. De söker vägledning från en administrativ myndighet, som tolkar lagen på ett specifikt sätt. Efter Chevron-beslutet, om en domstol granskar denna myndighets tolkning och finner den rimlig, kommer den troligen att hålla med myndigheten, vilket gör att fabriken kan fortsätta under dessa regler."
                },
                {
                    "startIdx": 68,
                    "endIdx": 146,
                    "keyword": "Chevron U.S.A. Inc. v. Natural Resources Defense Council, Inc., 467 U.S. 837 (1984)",
                    "definition": "Det banbrytande högsta domstolsfallet \"Chevron U.S.A. Inc. v. Natural Resources Defense Council, Inc., 467 U.S. 837 (1984)\" etablerade den juridiska doktrin som kallas 'Chevron-deferens.' Denna doktrin föreskriver att domstolar ska ge hänsyn till en federal myndighets tolkning av en lag som den administrerar om lagen är tvetydig och myndighetens tolkning är rimlig. Fallet uppstod ur en tvist om Environmental Protection Agency's (EPA) tolkning av Clean Air Act. Chevron-deferensen har sedan dess blivit en grundläggande princip inom förvaltningsrätt, och påverkar hur domstolar granskar myndighetsregleringar och beslut.",
                    "simplified": "I högsta domstolsfallet \"Chevron U.S.A. Inc. v. Natural Resources Defense Council, Inc., 467 U.S. 837 (1984),\" fastslog domstolen att om en lag är otydlig bör domstolar generellt lita på en statlig myndighets rimliga tolkning av den lagen. Detta beslut, känt som Chevron-deferens, har stor påverkan på hur domstolar hanterar tvister som involverar statliga regleringar.",
                    "example": "Föreställ dig en situation där Environmental Protection Agency (EPA) skapar en ny regel under Clean Air Act. Om någon ifrågasätter regeln i domstol, kan domstolen tillämpa Chevron-deferens, vilket innebär att den troligen kommer att hålla med EPA:s tolkning av lagen så länge den är rimlig. Denna doktrin hjälper myndigheter som EPA att effektivt upprätthålla lagar, men den väcker också frågor om omfattningen av deras makt."
                },
                {
                    "startIdx": 211,
                    "endIdx": 217,
                    "keyword": "reasonable",
                    "definition": "Begreppet \"rimlig\", i kontexten av rättslig tolkning, avser en standard som tillämpas av domstolar och som erkänner behovet av flexibilitet och diskretion vid tolkningen av tvetydiga lagar. Denna standard tillåter domare att ge hänsyn till tolkningar som är logiska och försvarbara, särskilt i fall där tidigare prejudikat kanske inte ger tydlig vägledning. Därför är en \"rimlig\" tolkning en som överensstämmer med lagens avsikt samtidigt som den tar hänsyn till de bredare implikationerna och kontexten av juridiska principer.",
                    "simplified": "\"Rimlig\" är en flexibel standard som domstolar använder för att tolka oklara lagar, och föredrar tolkningar som är logiska och försvarbara, särskilt i unika fall utan tidigare prejudikat.",
                    "example": "Föreställ dig att en ny lag antas som syftar till att förbättra integriteten online, men den använder vag språk om vad som utgör \"personuppgifter.\" Om ett företag stäms för hur de hanterade användarinformation, kan en domare titta på lagen och avgöra vad som är en \"rimlig\" tolkning av den termen, med tanke på lagens mål att skydda integritet och den allmänna förståelsen av personuppgifter. Detta tillåter domstolen att tillämpa lagen rättvist, även om det inte finns några tidigare fall som direkt kan vägleda dem."
                },
                {
                    "startIdx": 261,
                    "endIdx": 278,
                    "keyword": "agency",
                    "definition": "I detta sammanhang avser \"myndighet\" en statlig instans eller organisation som har befogenhet att genomföra och verkställa specifika lagar och förordningar. Sådana myndigheter har auktoritet att tolka otydliga lagar, vilket kan innebära att de gör bedömningar om hur lagar bör tillämpas i praktiken, särskilt i fall som tidigare inte har prövats. Den tolkning som ges av myndigheterna är ofta betydelsefull för att forma rättspraxis och kan påverka efterföljande domstolsbeslut, som vi ser i fall som Mayo Foundation.",
                    "simplified": "I det här sammanhanget syftar \"myndighet\" på en statlig organisation som har befogenhet att tillämpa och förtydliga lagar, särskilt i situationer där den juridiska texten är otydlig, vilket påverkar framtida juridiska tolkningar och avgöranden.",
                    "example": "Föreställ dig en myndighet, som Miljöskyddsverket (EPA), som står inför en ny lag om luftkvalitetsstandarder som inte är särskilt tydlig. De kan tolka denna oklara lagstiftning som att vissa fabriker behöver minska sina utsläpp mer än vad som tidigare antagits, vilket kan leda till strängare regler och böter för dem som inte följer dem. Denna tolkning hjälper inte bara till att klargöra lagen utan sätter också ett prejudikat för hur liknande fall kommer att hanteras i framtiden."
                },
                {
                    "startIdx": 313,
                    "endIdx": 363,
                    "keyword": "Mayo Foundation for Medical Education and Research",
                    "definition": "\"Mayo Foundation\" avser i detta sammanhang en organisation eller institution som är etablerad för ett specifikt syfte, ofta relaterat till utbildning, forskning eller välgörenhetsverksamhet. Fallet framhävde frågor kring statlig immunitet från stämningar, vilket illustrerar komplexiteten i att hålla offentliga enheter ansvariga för försumlighet, särskilt inom medicinska och utbildningsinstitutioner. Implikationerna av denna doktrin återspeglar långvariga principer från engelsk common law som skyddar statliga enheter, vilket därmed väcker frågor om rättvisa och ansvarsskyldighet inom offentliga tjänster.",
                    "simplified": "\"Mayo Foundation\" betecknar en organisation som skapats för ett särskilt uppdrag, oftast involverande utbildning, forskning eller välgörenhet, och fallet understryker utmaningarna i att hålla statliga enheter ansvariga för försumlighet inom dessa sektorer.",
                    "example": "Föreställ dig ett scenario där ett offentligt sjukhus, finansierat av Mayo Foundation, gör ett allvarligt misstag under en medicinsk procedur. På grund av den juridiska doktrinen om statlig immunitet kan patienter som skadas av detta misstag ha svårt att hålla sjukhuset ansvarigt i domstol, vilket leder till debatter om rättvisan i att tillåta offentliga institutioner att verka utan rädsla för stämningar. Denna situation belyser vikten av att förstå hur enheter som Mayo Foundation, som juridiska personer, kan påverka tillgången till rättvisa och ansvarsskyldighet inom sjukvården."
                },
                {
                    "startIdx": 520,
                    "endIdx": 532,
                    "keyword": "Sec. 7805(a)",
                    "definition": "Section 7805(a) i den interna skattelagen ger finansministern befogenhet att utfärda alla nödvändiga regler och förordningar för att upprätthålla skattelagarna. Denna bestämmelse ger finansdepartementet bred diskretion att tolka och implementera skatteregler, inklusive utfärdandet av föreskrifter och vägledningar. Rättsliga tvister uppstår ofta kring omfattningen av denna befogenhet, särskilt när föreskrifter som utfärdats enligt Sec. 7805(a) ifrågasätts som överdrivna eller oförenliga med lagens avsikt.",
                    "simplified": "Section 7805(a) ger finansdepartementet makt att skapa regler och förordningar som behövs för att upprätthålla skattelagar. Ibland utmanas dessa föreskrifter i domstol om de anses gå utöver vad lagen tillåter.",
                    "example": "Föreställ dig en situation där finansdepartementet utfärdar en ny skatteregel enligt Sec. 7805(a) som ändrar hur vissa avdrag beräknas. En skattskyldig kan ifrågasätta denna regel och hävda att den strider mot lagens ursprungliga avsikt. Domstolens beslut kan påverka hur mycket frihet finansdepartementet har i att tolka skatteregler och skapa föreskrifter, vilket påverkar skattskyldiga i hela landet."
                },
                {
                    "startIdx": 568,
                    "endIdx": 587,
                    "keyword": "Finansdepartementet",
                    "definition": "Finansdepartementet är ett federalt verkställande departement som ansvarar för att förvalta statens finanser, inklusive att samla in intäkter, betala räkningar, hantera federala finanser och producera valuta. Det spelar en kritisk roll i att formulera och genomföra ekonomisk politik, övervaka Internrevisionsverket (IRS), och upprätthålla federala finans- och skattelagar. Rättsfall som involverar Finansdepartementet behandlar ofta frågor relaterade till beskattning, finansiell reglering och ekonomiska sanktioner.",
                    "simplified": "Finansdepartementet är en federal myndighet som hanterar statens pengar, inklusive att samla in skatter och upprätthålla skattelagar. Det hjälper också till att forma landets ekonomiska politik.",
                    "example": "Föreställ dig ett scenario där ett företag utmanar en ny skatteregel som utfärdats av Finansdepartementet och hävdar att den orättvist ökar deras skattebörda. Domstolens beslut kan påverka hur Finansdepartementet upprätthåller skattelagar och påverkar den bredare ekonomin, vilket återspeglar departementets betydande roll i finansiell reglering."
                },

                {
                    "startIdx": 667,
                    "endIdx": 672,
                    "keyword": "code",
                    "definition": "I sammanhanget syftar \"kod\" på en systematisk samling av regler, förordningar och lagar som styr beteende och procedurer inom specifika jurisdiktioner, inklusive kommunala, läns- och statliga nivåer samt federala nivåer. Även om koderna i sig kanske inte är lagar i strikt mening, så har de juridisk auktoritet på grund av att de härstammar från lagstiftande beslut, och de kan ofta medföra straff för bristande efterlevnad. Dessutom betonar termen den ram inom vilken domare verkar, vilket möjliggör diskretionär beslutsfattande som överensstämmer med fastställda koder, samtidigt som det fortfarande är föremål för överklagande granskning.",
                    "simplified": "I det här sammanhanget betecknar \"kod\" en organiserad uppsättning riktlinjer och regler som styr beteende och procedurer på olika nivåer av regeringen, vilka, även om de inte alltid klassificeras som lagar, har rättslig tyngd eftersom de härstammar från lagstadgad auktoritet och kan ålägga straff för överträdelser, vilket påverkar rättsligt omdöme och överklagandegranskning.",
                    "example": "Föreställ dig att en stad har en byggnadsstandard som kräver att alla nya konstruktioner uppfyller specifika säkerhetsnormer, som att ha nödutgångar och korrekt elektrisk installation. Om en entreprenör ignorerar dessa regler och bygger en struktur som inte följer dem, kan staden ålägga böter eller till och med stoppa projektet tills det uppfyller standardkraven. Detta säkerställer att alla byggnader är säkra för invånarna och visar hur regler spelar en avgörande roll i att skydda allmänhetens välfärd."
                },

                {
                    "startIdx": 828,
                    "endIdx": 883,
                    "keyword": "Loper Bright Enterprises, Inc. mot Raimondo, No. 22-451",
                    "definition": "I det rättsliga fallet \"Loper Bright Enterprises, Inc. mot Raimondo, No. 22-451,\" handlar tvisten om regleringsmyndighetens omfattning och tolkningen av administrativ rätt. Fallet fokuserar på hur mycket makt federala myndigheter har att tolka lagar som påverkar företag, särskilt i industrier som är föremål för omfattande reglering. Beslutet i detta fall kan få långtgående konsekvenser för hur mycket hänsyn domstolar ger till myndigheters tolkningar av lagen, vilket potentiellt kan förändra maktbalansen mellan regeringen och privata företag.",
                    "simplified": "I fallet \"Loper Bright Enterprises, Inc. mot Raimondo, No. 22-451\" är fokus på hur mycket auktoritet federala myndigheter har att tolka lagar som påverkar företag, särskilt i starkt reglerade industrier. Utfallet kan förändra hur domstolar ser på myndigheters makt och påverka relationen mellan regeringen och privata företag.",
                    "example": "Föreställ dig ett scenario där ett fiskeföretag, som Loper Bright Enterprises, inte håller med om en ny federal reglering som införts av en myndighet. Företaget hävdar att myndigheten överskridit sin befogenhet i tolkningen av lagen. Om domstolen ställer sig på företagets sida kan det leda till minskad makt för myndigheter att fastställa regler, vilket påverkar hur företag styrs i olika sektorer. Detta fall belyser den pågående debatten om regeringens makt att reglera industrier och vikten av rättslig tillsyn för att upprätthålla balansen mellan allmänna intressen och privata rättigheter."
                },
                {
                    "startIdx": 900,
                    "endIdx": 921,
                    "keyword": "oral argument",
                    "definition": "En muntlig argumentation är en formell presentation som görs av advokater inför en domstol, där de framhäver sitt fall och svarar på frågor som ställs av domarna. Denna process möjliggör för domstolen att klargöra rättsliga och faktiska punkter, vilket underlättar en mer informerad beslutsprocess gällande överklagandet, som sett i fall som *ses, Inc. v. Raimondo*. Möjligheten till muntlig argumentation understryker vikten av direkt dialog i granskningsprocessen, vilket särskiljer den från skriftliga inlagor som lämnas in före förhandlingen.",
                    "simplified": "Ett muntligt argument är en strukturerad diskussion där advokater presenterar sitt fall för en domstol och svarar på domarnas frågor, vilket hjälper till att klargöra juridiska frågor och fakta för att stödja beslutsprocessen i överklagandeärenden.",
                    "example": "Föreställ dig en situation där ett företag anser att det blivit orättvist beskattat på grund av en felaktig klassificering av sina produkter. Under den muntliga förhandlingen i *ses, Inc. v. Raimondo* presenterar advokaterna sitt fall för domarna och förklarar varför skatteklassificeringen bör ändras, samtidigt som de besvarar svåra frågor från domarna om lagen och bevisen. Denna direkta interaktion hjälper domarna att förstå komplexiteten i fallet, vilket säkerställer att de kan fatta ett mer informerat beslut när de granskar överklagandet."
                },
                {
                    "startIdx": 928,
                    "endIdx": 984,
                    "keyword": "Relentless, Inc. mot Department of Commerce, No. 22-1219",
                    "definition": "I det rättsliga fallet \"Relentless, Inc. mot Department of Commerce, No. 22-1219,\" handlar tvisten om handelsdepartementets regleringsbefogenheter över kommersiella fiskemetoder. Fallet fokuserar på tolkningen av federala lagar som reglerar marina resurser, där Relentless, Inc. utmanar departementets regler som alltför restriktiva och skadliga för deras affärsverksamhet. Utfallet av detta fall kan få betydande konsekvenser för balansen mellan miljöskyddsinsatser och fiskerinäringens ekonomiska intressen.",
                    "simplified": "I fallet \"Relentless, Inc. mot Department of Commerce, No. 22-1219\" är frågan om handelsdepartementets fiskeregler är för strikta och negativt påverkar fiskerinäringen. Fallet kan påverka hur mycket kontroll regeringen har över fiskemetoder jämfört med att skydda miljön.",
                    "example": "Föreställ dig ett scenario där ett fiskeföretag som Relentless, Inc. måste följa nya regler som begränsar hur mycket fisk de får fånga för att skydda vissa arter. Företaget hävdar att dessa regler är för hårda och skadar deras verksamhet. Om domstolen ställer sig på företagets sida kan det leda till förändringar i hur regeringen upprätthåller fiskeregler, vilket potentiellt kan lätta på restriktioner för industrin samtidigt som det väcker oro för miljöskyddet."
                },
                {
                    "startIdx": 1060,
                    "endIdx": 1093,
                    "keyword": "National Marine Fisheries Service",
                    "definition": "National Marine Fisheries Service (NMFS) är en federal myndighet som ansvarar för förvaltningen av nationens havsresurser och deras livsmiljö. I juridiska sammanhang, särskilt i fall som Loper Bright Enterprises, Inc. mot Raimondo, spelar NMFS ofta en central roll på grund av sin regleringsbefogenhet över marina fiskerier och upprätthållandet av lagar som Magnuson-Stevens Fishery Conservation and Management Act. Myndighetens beslut kan ha betydande ekonomiska och miljömässiga konsekvenser, vilket leder till tvister om omfattningen av dess regleringsmakt och tolkningen av federala lagar.",
                    "simplified": "National Marine Fisheries Service (NMFS) är en federal myndighet som förvaltar landets havsresurser. Den reglerar fisket och upprätthåller relaterade lagar, vilket ibland leder till juridiska tvister om hur mycket makt den har och hur den tolkar dessa lagar.",
                    "example": "Föreställ dig ett scenario där National Marine Fisheries Service inför strikta fiskekvoter för att skydda en viss fiskart. Ett fiskeföretag kan utmana dessa kvoter i domstol och hävda att myndigheten har överskridit sina befogenheter. Utfallet av ett sådant fall kan påverka hur NMFS reglerar fiskerinäringen i framtiden, vilket påverkar både miljöskyddsinsatser och fiskesamhällens ekonomiska intressen."
                },
                {
                    "startIdx": 1168,
                    "endIdx": 1180,
                    "keyword": "D.C. Circuit",
                    "definition": "D.C. Circuit, formellt känd som United States Court of Appeals for the District of Columbia Circuit, är en av de mest inflytelserika federala appellationsdomstolarna i USA. Den har jurisdiktion över fall som uppstår i District of Columbia och är särskilt känd för att behandla fall som involverar federala förordningar, administrativ rätt och frågor som rör federala myndigheter. Beslut från D.C. Circuit kan ha betydande konsekvenser över hela landet, eftersom många fall involverar utmaningar mot federala policyer och regleringar som påverkar hela nationen.",
                    "simplified": "D.C. Circuit är en mäktig federal appellationsdomstol som ofta behandlar fall som involverar statliga förordningar och federala myndigheter. Dess beslut kan ha stor påverkan över hela landet eftersom de ofta rör viktiga federala policyer.",
                    "example": "Föreställ dig ett fall där en ny miljöregel utmanas av ett företag i D.C. Circuit. Domstolens beslut om huruvida regeln är laglig kan skapa ett prejudikat som påverkar liknande regler över hela landet. Detta visar D.C. Circuits avgörande roll i att forma hur federala lagar och policyer tillämpas över hela USA."
                },
                {
                    "startIdx": 1185,
                    "endIdx": 1198,
                    "keyword": "Första Circuit",
                    "definition": "Första Circuit, formellt känd som United States Court of Appeals for the First Circuit, är en federal appellationsdomstol med jurisdiktion över fall från staterna Maine, Massachusetts, New Hampshire, Rhode Island och Puerto Rico. Första Circuit hör överklaganden från federala distriktsdomstolar inom dess jurisdiktion och är känd för sina avgöranden i ett brett spektrum av juridiska frågor, inklusive konstitutionell rätt, medborgerliga rättigheter och kommersiella tvister. Även om den är mindre i omfång jämfört med andra cirkulationer, kan Första Circuits beslut ha en betydande inverkan på den juridiska landskapet i dess region.",
                    "simplified": "Första Circuit är en federal appellationsdomstol som täcker Maine, Massachusetts, New Hampshire, Rhode Island och Puerto Rico. Den hanterar överklaganden om olika juridiska frågor och dess beslut påverkar lagar och förordningar i dessa stater och territorier.",
                    "example": "Föreställ dig ett fall där ett företag i Massachusetts överklagar en federal dom till Första Circuit och hävdar att en ny arbetslag kränker deras rättigheter. Första Circuits beslut kan påverka hur liknande lagar tillämpas i hela regionen och forma de juridiska standarderna som företag och individer måste följa i de stater och territorier som ligger under dess jurisdiktion."
                }
            ]
        
        }
    }
}

export default legalData;
