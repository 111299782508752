import React, { useState, useRef } from 'react';
import './Popup.css';

const Popup = ({
    id,
    setId,
    isVisible,
    setIsVisible,
    title="Title",
    definition="Lorem definition...",
    simplified="Lorem simplified...",
    example="Lorem example...",
    position
}) => {
    const [sections, setSections] = useState([simplified]);
    const [exampleIdx, setExampleIdx] = useState(null);
    const [simplifiedIdx, setSimplifiedIdx] = useState(null);

    const sectionRefs = useRef([]);
    const containerRef = useRef(null);

    const handleMouseEnter = () => {
        setId(id);
        setIsVisible(true);
    };

    const handleMouseLeave = () => {
        setIsVisible(false);
        setExampleIdx(null);
        setSimplifiedIdx(null);
        setId(null);
    };

    const scrollToSection = (sectionIdx) => {
        setTimeout(() => {
            const scrollSectionRef = sectionRefs.current[sectionIdx];
            if (scrollSectionRef && containerRef.current) {
                containerRef.current.scrollTo({
                      top: scrollSectionRef.offsetTop,
                      behavior: 'smooth',
                });
            }
        }, 0);
    }

    const addSection = (sectionName, sectionIdx, setSectionIdx) => {
        if (sectionIdx !== null) {
            scrollToSection(sectionIdx);
            return;
        } 

        let newSection;
        switch (sectionName) {
            case "example":
                newSection = example;
                break;
            case "simplified":
                newSection = definition;
                break;
            default:
                throw new Error("Must be an existing section");
        }

        const newIdx = sections.length;

        setSections((prevSections) => [...prevSections, newSection]);
        setSectionIdx(newIdx);

        scrollToSection(newIdx);

        return;
    };

    return (
        <div
            id={`explanation-${id}`}
            className="pop-up"
            style={{ 
                top: position.top, 
                left: position.left,
                visibility: isVisible ? 'visible' : 'hidden',
                transition: 'visibility 0s, opacity 0.25s linear',
                opacity:  isVisible ? 1 : 0,
            }}
            onMouseEnter={ handleMouseEnter }
            onMouseLeave={ handleMouseLeave }
        >
            <div className="popup-header">
                <h3 className="popup-title">{title}</h3>
                <button 
                    className="popup-header-button"
                    onClick={() => scrollToSection(0)} 
                >
                    &#8743;
                </button>
            </div>
            <hr className="popup-divider" />
            <div 
                className="popup-content-container"
                ref={containerRef}
            >
                {sections.map((section, idx) => (
                    <div
                        key={idx}
                        ref={(el) => (sectionRefs.current[idx] = el)}
                    >
                        { idx !== 0 && (
                            <hr className="popup-divider" />
                        )}
                        <div className="popup-content">
                            { idx === exampleIdx && (
                                <div>
                                    Example: <br/>
                                </div>
                            )}

                            { idx === simplifiedIdx && (
                                <div>
                                    Further Explanation: <br/>
                                </div>
                            )}

                            {section}
                        </div>
                    </div>
                ))}
            </div>
            <hr className="popup-divider" />
            <div className="popup-actions">
                <button 
                    className="popup-button"
                    onClick={() => {
                        addSection(
                            "example",
                            exampleIdx, 
                            setExampleIdx
                        );
                    }}
                >
                    Give Examples
                </button>
                <button 
                    className="popup-button"
                    onClick={() => {
                        addSection(
                            "simplified", 
                            simplifiedIdx, 
                            setSimplifiedIdx
                        );
                    }}
                >
                    Further Explanation
                </button>
            </div>
        </div>
    );
};

export default Popup;
