import React, { useState } from 'react';
import financeGlossary from '../glossary/finance_glossary.json';
import lawGlossary from '../glossary/law_glossary.json';
import './auth_glossary.css';

const AuthGlossary = () => {
  const glossaryOptions = {
    Finance: financeGlossary,
    Law: lawGlossary,
    // Add more glossaries here if needed
  };

  const [selectedGlossary, setSelectedGlossary] = useState('Finance');
  const [glossary, setGlossary] = useState(glossaryOptions['Finance']);
  const [editIndex, setEditIndex] = useState(null);
  const [editedResponse, setEditedResponse] = useState('');
  const [filter, setFilter] = useState('All');

  // Generate index letters A-Z and numbers 0-9
  const generateIndex = () => {
    const letters = Array.from({ length: 26 }, (_, i) =>
      String.fromCharCode(65 + i)
    );
    const numbers = Array.from({ length: 10 }, (_, i) => i.toString());
    return ['All', ...letters, ...numbers];
  };

  const indexList = generateIndex();

  // Handle glossary selection from dropdown
  const handleGlossaryChange = (e) => {
    const glossaryName = e.target.value;
    setSelectedGlossary(glossaryName);
    setGlossary(glossaryOptions[glossaryName]);
    setFilter('All'); // Reset filter when glossary changes
    setEditIndex(null); // Exit edit mode if any
  };

  // Handle index click to filter glossary
  const handleFilterClick = (char) => {
    setFilter(char);
    if (char === 'All') {
      setGlossary(glossaryOptions[selectedGlossary]);
    } else {
      setGlossary(
        glossaryOptions[selectedGlossary].filter((item) =>
          item.keyword.startsWith(char)
        )
      );
    }
    setEditIndex(null); // Exit edit mode when filter changes
  };

  // Handle edit button click
  const handleEditClick = (index) => {
    setEditIndex(index);
    setEditedResponse(glossary[index].response);
  };

  // Handle save button click
  const handleSaveClick = (index) => {
    const updatedGlossary = [...glossary];
    updatedGlossary[index].response = editedResponse;
    setGlossary(updatedGlossary);
    setEditIndex(null);
    // Future Enhancement: Save changes to the database here
  };

  // Handle cancel button click
  const handleCancelClick = () => {
    setEditIndex(null);
    setEditedResponse('');
  };

  return (
    <div className="glossary-container">
      {/* Glossary Selection Dropdown */}
      <div className="glossary-selection">
        <label htmlFor="glossary-select" className="glossary-label">
          Select Glossary:
        </label>
        <select
          id="glossary-select"
          value={selectedGlossary}
          onChange={handleGlossaryChange}
          className="glossary-dropdown"
        >
          {Object.keys(glossaryOptions).map((glossaryName, idx) => (
            <option key={idx} value={glossaryName}>
              {glossaryName}
            </option>
          ))}
        </select>
      </div>

      {/* Glossary Index Navigation */}
      <div className="glossary-index">
        {indexList.map((char, idx) => (
          <button
            key={idx}
            className={`index-button ${filter === char ? 'active' : ''}`}
            onClick={() => handleFilterClick(char)}
          >
            {char}
          </button>
        ))}
      </div>

      {/* Glossary Table */}
      <div className="table-responsive">
        <table className="glossary-table">
          <thead>
            <tr>
              <th>Keyword</th>
              <th>Definition</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {glossary.length > 0 ? (
              glossary.map((item, index) => (
                <tr key={index}>
                  <td className="keyword">{item.keyword}</td>
                  <td className="definition">
                    {editIndex === index ? (
                      <textarea
                        value={editedResponse}
                        onChange={(e) => setEditedResponse(e.target.value)}
                        className="edit-textarea"
                      />
                    ) : (
                      item.response
                    )}
                  </td>
                  <td className="actions">
                    {editIndex === index ? (
                      <>
                        <button
                          className="save-button"
                          onClick={() => handleSaveClick(index)}
                          aria-label={`Save definition for ${item.keyword}`}
                        >
                          Save
                        </button>
                        <button
                          className="cancel-button"
                          onClick={handleCancelClick}
                          aria-label={`Cancel editing for ${item.keyword}`}
                        >
                          Cancel
                        </button>
                      </>
                    ) : (
                      <button
                        className="edit-button"
                        onClick={() => handleEditClick(index)}
                        aria-label={`Edit definition for ${item.keyword}`}
                      >
                        Edit
                      </button>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="3" className="no-results">
                  No glossary terms found for "{filter}".
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AuthGlossary;
