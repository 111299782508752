
import React from 'react';
import './OurTeam.css';

const OurTeam = () => {
  return (
    <section className="our-team" id="team">
      <h2>Our Team</h2>
      <div className="team-members">
        <div className="team-member">
          <div className="member-photo">
            <img src={`${process.env.PUBLIC_URL}/images/avid1.png`} alt="Avid" />
          </div>
          <h3>Avid Fayaz</h3>
          <hr />
          <p>Avid is Sibel's project manager and backend developer. His work is to ensure that Sibel works as smoothly as possible and can be easily integrated into our clients' websites.</p>
          <p>He is currently in his third year of studies in computer science at KTH. Where he is employed as a research assistent in projects involving the integration of AI in programming courses.</p>
          <p>Outside of his studies he hosts a scientific podcast, The AI Pod, interviewing researches about their work. He also enjoys playing and listening to classical music, and reading about finance and economics.</p>
          <a href="https://www.linkedin.com/in/avid-fayaz/" target="_blank" rel="noopener noreferrer">
            <img src={`${process.env.PUBLIC_URL}/images/Linkdin30.png`} alt="LinkedIn" className="linkedin-icon" />
          </a>
        </div>
        <div className="team-member">
          <div className="member-photo">
            <img src={`${process.env.PUBLIC_URL}/images/emil.png`} alt="Emil" />
          </div>
          <h3>Emil Hultcrantz</h3>
          <hr />
          <p>Emil is the database administrator and frontend developer of Sibel. His work involves developing and structuring the glossaries on which our model is based.</p>
          <p>He is currently studying his fourth year of computer science at KTH Royal Institute of Technology. He is passionate about everything related to computer science, something he has brought to his work as a teacher assistant at KTH.</p>
          <p>In recent years he has also devoted a lot of time to the CS student chapter at KTH, most importantly as a member of the board. In his free time he enjoys working out, theatre, and making music with his brother.</p>
          <a href="https://www.linkedin.com/in/emil-hultcrantz-8a0bba1a8/?originalSubdomain=se" target="_blank" rel="noopener noreferrer">
            <img src={`${process.env.PUBLIC_URL}/images/Linkdin30.png`} alt="LinkedIn" className="linkedin-icon" />
          </a>
        </div>
        <div className="team-member">
          <div className="member-photo">
            <img src={`${process.env.PUBLIC_URL}/images/isak.png`} alt="Isak" />
          </div>
          <h3>Isak Lefèvre</h3>
          <hr/>
          <p>Isak is Sibel's backend and AI developer. His role is to develop and refine our models to ensure that the quality of our outputs are always robust, correct, and secure.</p>
          <p>Isak is a third year computer science student at KTH and has previously received a grant from OpenPhilantropy to study Deep Learning and AI Safety parallel to his studies at KTH. </p>
          <p>He has a passion for learning new things, from computer science and math to philosophy and history. Apart from his studies he makes himself busy with reading, hiking, and singing.</p>
          <a href="https://www.linkedin.com/in/isak-lefevre" target="_blank" rel="noopener noreferrer">
            <img src={`${process.env.PUBLIC_URL}/images/Linkdin30.png`} alt="LinkedIn" className="linkedin-icon" />
          </a>
        </div>
      </div>
    </section>
  );
};

export default OurTeam;
